import AddAndUpdateUserBody from "./AddAndUpdateUserBody";
import { dateFormatDB } from "../../utils/constants";
import { format } from "date-fns";

export default function UserOrgEdit(props) {
    const { userInfo, handleForm, dashboardRequest } = props;
    console.log(userInfo);
    return (
        <form onSubmit={e => { e.preventDefault(); }}>
            <AddAndUpdateUserBody
                organization_id={userInfo.user_organization_id}
                dashboardRequest={dashboardRequest}
                handleForm={handleForm}
                isNew={false}
                body={{
                    user_id:userInfo.id,
                    organization_id: userInfo.user_organization_id,
                    birthday_obj: new Date(userInfo.birthday_date),
                    birthday: format(new Date(userInfo.birthday_date), dateFormatDB),
                    hired_obj: new Date(userInfo.hired_date),
                    hired: format(new Date(userInfo.hired_date), dateFormatDB),
                    complete_name: userInfo.complete_name,
                    user_name: userInfo.user_name,
                    account_active: true,
                    location: userInfo.user_city,
                    position: userInfo.user_position,
                    area:userInfo.user_area,
                    email: userInfo.email,
                    pwd: "",
                    superior_id: userInfo.user_superior_id,
                    phone_number: userInfo.phone_number,
                    blood_type:userInfo.blood_type,
                    user_type:userInfo.roles.userType,
                    nss:userInfo.nss,
                    diseases:!userInfo.diseases?{}:userInfo.diseases,
                    insurance_beneficiaries:!userInfo.insurance_beneficiaries ? {}:userInfo.insurance_beneficiaries,
                    permissions:!userInfo.roles.permissions?{}:userInfo.roles.permissions
                }}
            />
        </form>
    );
}