export const yearFormat = "yyyy";
export const monthFormat = "MMMM";
export const dateFormat = "MMMM yyyy";
export const dateFormatCard = "yyyy-MMMM-dd";
export const dateFormatDB = "yyyy-MM-dd";
export const dateFormatExpired = "MM/yy";

export const statusToColor = {
    "REJECTED":"bg-red-500",
    "APPROVED": "bg-green-500",
    "AWAITING":"bg-yellow-500"
}