
import { format, addMonths, subMonths } from 'date-fns';
import { dateFormat } from '../../utils/constants';

export default function MonthPicker(props) {
    const { title, selectMonth, setSelectMonth } = props;
    const header = () => {

        return (
            <div className="flex justify-between items-center">
                <button
                    type="button"
                    onClick={prevMonth}
                    className="p-1 rounded-full hover:bg-gray-200">
                    &#10094;
                </button>
                <span className="text-xl font-semibold">{format(selectMonth, dateFormat)}</span>
                <button
                    type="button"
                    onClick={nextMonth}
                    className="p-1 rounded-full hover:bg-gray-200">
                    &#10095;
                </button>
            </div>
        );
    };

    const nextMonth = () => {
        setSelectMonth(addMonths(selectMonth, 1));
    };

    const prevMonth = () => {
        setSelectMonth(subMonths(selectMonth, 1));
    };

    return (
        <div className="form-control mb-6">
            <label for="large-input" class="block mb-2 text-sm font-medium text-black">{title}</label>
            <div className="w-full p-4 bg-white border border-gray-400 rounded-lg shadow-lg">
                {header()}
            </div>
        </div>

    );
};

