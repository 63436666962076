import { useState } from "react";
import ReportViewer from "../form/ReportViewer";
import { getUserData } from "../../utils/utils";
export default function GridTableHead({ title, addClickHandle, filterValues, 
    dashboardRequest, cardsData, disableReport=true, diableAdd = false }) {
    const [showReport, setShowReport] = useState(false);
    const userInfo = getUserData();
    return (
        <>
            <div className="navbar bg-base-100 mb-4 rounded-xl ">
                <div className="flex-1">
                    <a className="btn btn-ghost text-xl">{title}</a>
                </div>
                <div className="navbar-end">
                    {
                        ( !disableReport) ?
                        (<button
                            onClick={() => setShowReport(true)}
                            className="btn btn-square btn-info">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m14 9.006h-.335a1.647 1.647 0 0 1-1.647-1.647v-1.706a1.647 1.647 0 0 1 1.647-1.647L19 12M5 12v5h1.375A1.626 1.626 0 0 0 8 15.375v-1.75A1.626 1.626 0 0 0 6.375 12H5Zm9 1.5v2a1.5 1.5 0 0 1-1.5 1.5v0a1.5 1.5 0 0 1-1.5-1.5v-2a1.5 1.5 0 0 1 1.5-1.5v0a1.5 1.5 0 0 1 1.5 1.5Z" />
                            </svg>
                        </button>):null
                    }
                    {
                        !diableAdd || userInfo.isAdmin ? 
                        <button
                        onClick={addClickHandle}
                        className="btn btn-square btn-success ml-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>:null}
                </div>
            </div>
            {showReport &&
                (<ReportViewer
                    filterValues={filterValues}
                    handleForm={setShowReport}
                    dashboardRequest={dashboardRequest} />)
            }
        </>

    );
}