import axios from "axios";
import UserList from "../../components/ui/userList";
import ScheduleAdmin from "../../components/ui/ScheduleAdmin";
import { useState } from "react";
import VacationList from "../../components/ui/VacationList";
import { format } from "date-fns";

import { toast } from "react-toastify";

function UserAdmin() {
	const [userSelected, setUserSelected] = useState({
		id: [],
		name: [],
	});
	const [dateSelected, setDateSelected] = useState();
	const [refresh, setRefresh] = useState(false);
	const [presetEvent, setPresetEvent] = useState({
		title: "",
		color: "#0991b2",
	});

	const today = new Date();

	// ✅ Get the first day of the current week (Sunday)
	const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 8));

	// ✅ Get the last day of the current week (Saturday)
	const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

	const [emailSubjectSchedule, setEmailSubjectSchedule] = useState(
		"Schedule from: " +
			format(firstDay, "dd/MM/yyyy") +
			" to: " +
			format(lastDay, "dd/MM/yyyy")
	);
	console.log(emailSubjectSchedule);

	console.log("userSelected: ", userSelected);

	const changeTab = index => {
		console.log(index);
		setUserSelected(index);
	};

	const changeDate = index => {
		console.log(index);
		setDateSelected(index.dateStr);
	};

	const handleEventForm = async event => {
		event.preventDefault();

		const title = event.target.eventTitle.value;
		const description = event.target.eventDescription.value;
		const start = event.target.eventStart.value;
		const end = event.target.eventEnd.value;
		const color = event.target.eventColor.value;
		const userId = event.target.userId.value;

		if (title !== "" || start !== "" || end !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "events";
			try {
				const response = await axios.post(
					endpoint,
					{
						title: title,
						description: description,
						start: start,
						end: end,
						allDay: true,
						backgroundColor: color.slice(1),
						borderColor: color.slice(1),
						createdBy: localStorage.getItem("userId"),
						userId: userId,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}

		console.log(title, description, start, end);

		//const userEmail = event.target.userEmail.value;
	};

	const handleEmailForm = async email => {
		email.preventDefault();

		const emailBody =
			email.target.emailBody.value + " /r/n https://www.pac-test.com";
		const emailSubject = email.target.emailSubject.value;

		console.log(emailBody, emailSubject);

		if (emailBody !== "" && emailSubject !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "sendMail";
			try {
				const response = await axios.post(
					endpoint,
					{
						to: userSelected.mails.mailList.toString(),
						subject: emailSubject,
						body: emailBody,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("Email sent");
				//setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
	};

	return (
		<>
			<button class="btn" onclick="my_modal_3.showModal()">
				open modal
			</button>
			<dialog id="my_modal_3" class="modal">
				<form method="dialog" class="modal-box">
					<button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
						✕
					</button>
					<h3 class="font-bold text-lg">Hello!</h3>
					<p class="py-4">Press ESC key or click on ✕ button to close</p>
				</form>
			</dialog>

			<div
				tabIndex={0}
				className="bg-base-100 w-full p-1 rounded-lg mb-5 collapse collapse-arrow"
			>
				<input type="checkbox" />
				<div className="collapse-title text-xl font-medium">
					{userSelected?.name.length == 1 ? (
						<p>{userSelected.name[0]} / Schedule Functions</p>
					) : (
						<p>* Select one user * / Schedule Functions</p>
					)}
				</div>
				<div tabIndex={0} className="collapse-content">
					{userSelected?.id.length == 1 ? (
						<>
							<div className="divider">Add Event</div>

							<form onSubmit={handleEventForm}>
								<div className="w-full flex">
									<div className="form-control">
										<label className="label cursor-pointer mr-5">
											<input
												type="radio"
												name="eventType"
												className="radio checked:bg-cyan-600 mr-2"
												value="Custom"
												onClick={() =>
													setPresetEvent({
														title: "",
														color: "#0991b2",
													})
												}
											/>
											<span className="label-text">Custom</span>
										</label>
									</div>
									<div className="form-control">
										<label className="label cursor-pointer mr-5">
											<input
												type="radio"
												name="eventType"
												className="radio checked:bg-slate-400 mr-2"
												value="Day By Day"
												onClick={() =>
													setPresetEvent({
														title: "Day By Day",
														color: "#94a3b8",
													})
												}
											/>
											<span className="label-text">Day By Day</span>
										</label>
									</div>
									<div className="form-control">
										<label className="label cursor-pointer mr-5">
											<input
												type="radio"
												name="eventType"
												className="radio checked:bg-amber-300 mr-2"
												value="Vacation"
												onClick={() =>
													setPresetEvent({
														title: "Vacation",
														color: "#fcd34d",
													})
												}
											/>
											<span className="label-text">Vacation</span>
										</label>
									</div>
								</div>
								<div className="border-1 grid grid-cols-2 gap-6 p-2">
									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Event Title</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="eventTitle"
											value={presetEvent.title}
											onChange={e =>
												setPresetEvent({
													title: e.target.value,
												})
											}
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Event Description / Additional Info</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="eventDescription"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Start Date</span>
										</label>
										<input
											type="date"
											placeholder="Type here"
											className="input input-bordered w-full "
											value={dateSelected}
											onChange={e => setDateSelected(e.target.value)}
											name="eventStart"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">End Date</span>
										</label>
										<input
											type="date"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="eventEnd"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Event Color</span>
										</label>
										<input
											type="color"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="eventColor"
											disabled
											value={presetEvent ? presetEvent.color : "#000000"}
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">User Id</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full"
											value={userSelected.id.toString()}
											name="userId"
											disabled
										/>
									</div>
								</div>
								<button type="submit" className="btn btn-wide mt-4 mr-2">
									SAVE
								</button>
							</form>
						</>
					) : (
						<></>
					)}

					<div className="divider">Send Email</div>
					<form onSubmit={handleEmailForm}>
						<div className="border-1 grid grid-cols-2 gap-6 p-2">
							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Email Subject</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="emailSubject"
									value={emailSubjectSchedule}
									onChange={e => setEmailSubjectSchedule(e.target.value)}
								/>
							</div>
							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Email Body</span>
								</label>
								<textarea
									className="textarea textarea-bordered h-24"
									placeholder="Type here"
									name="emailBody"
								></textarea>
							</div>
						</div>
						<button type="submit" className="btn btn-wide mt-4 mr-2">
							SEND
						</button>
					</form>
				</div>
			</div>

			<div className="flex flex-row bg-base-100 p-6 rounded-lg">
				<UserList changeTab={changeTab} usersSelected={userSelected} />
				{userSelected?.id ? (
					<div className="w-full">
						<ScheduleAdmin
							userIdSelected={userSelected.id}
							changeDate={changeDate}
							refresh={refresh}
						/>

						<VacationList
							userId={userSelected.id}
							userName={userSelected.name}
							refresh={refresh}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default UserAdmin;
