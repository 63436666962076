import { useState } from "react";


import UserOrgDocs from "./UserOrgDocs";
import UserOrgInfo from "./UserOrgInfo";
import UserOrgEdit from "./UserOrgEdit";
import TabSelector from "../inputs/TabSelector";


export default function UserOrgForm(props) {
    const { show, handleForm, title, userInfo, dashboardRequest, isAdmin, sessionUserId } = props;
    const [tabContent, setTabContent] = useState("INFO");
    return (
        <>
            {
                show &&
                (<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                        <h3 className="font-bold text-lg mb-4">{title}</h3>
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                        {   
                            parseInt(sessionUserId) === userInfo.id || isAdmin ?
                            <TabSelector
                                isAdmin={isAdmin}
                                tabContent={tabContent}
                                setTabContent={setTabContent}
                                mainStyle={isAdmin ?
                                    "grid grid-cols-3 gap-3 w-full text-center mb-4 border-b-4 border-blue-800" :
                                    "grid grid-cols-2 gap-3 w-full text-center mb-4 border-b-4 border-blue-800"
                                } /> : null
                        }
                        {{
                            "DOCS": <UserOrgDocs
                                isAdmin={isAdmin}
                                userInfo={userInfo}
                                setTabContent={setTabContent}
                                dashboardRequest={dashboardRequest}
                                handleForm={handleForm} />,
                            "EDIT": <UserOrgEdit
                                dashboardRequest={dashboardRequest}
                                userInfo={userInfo}
                                handleForm={handleForm}
                                organization_id={1} />,
                            "INFO": <UserOrgInfo
                                isAdmin={isAdmin}
                                userInfo={userInfo} />

                        }[tabContent]}
                    </div>
                </div>)
            }
        </>

    );
};

