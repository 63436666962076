import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useFetch from '../../hooks/useFetch';
import AlertMain from '../ui/AlertMain';
const chartOptions = (data, valueName) => {
    return {
        colors: ["#1A56DB", "#FDBA8C"],
        series: [
            {
                name: valueName,
                color: "#1A56DB",
                data: data,
            }
        ],
        chart: {
            type: "bar",
            fontFamily: "Inter, sans-serif",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "70%",
                borderRadiusApplication: "end",
                borderRadius: 4,
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            style: {
                fontFamily: "Inter, sans-serif",
            },
        },
        stroke: {
            show: true,
            width: 0,
            colors: ["transparent"],
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            height:100,
            floating: false,
            labels: {
                rotate: -45,
                show: true,
                rotateAlways: true,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 3,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 10,
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            show: true,
            axisBorder: {
                show: true,
                color: '#78909C',
                width: 3,
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0
            },
        }
    }
}

export default function ColumnChart(props) {
    const { title, filterValues, url, valueName } = props;
    const { data, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}${url}`,
        filterValues
    )
    useEffect(() => {
        handleRequest();
    },[filterValues])
    const options = chartOptions(data, valueName);
    return (


        <div className='w-full bg-white rounded-xl mt-6 px-10 pt-6 pb-10 shadow'>

            <div className="flex justify-center items-center">
                <h5 className="text-xl font-bold leading-none text-gray-900 pe-1">{title}</h5>
            </div>
            <AlertMain error={error} text={`Error! ${url}.`} />
            {loading ?
                <div className='flex flex-col items-center justify-center'>
                    <span className="loading loading-dots loading-lg mb-4 w-20 mt-2"></span>
                </div> :
                <>{data.length ?
                    <ReactApexChart
                        options={options}
                        series={options.series}
                        type="bar"
                        height={450}
                    /> : null
                }
                </>

            }
        </div>
    );
}


