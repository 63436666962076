import ReactECharts from "echarts-for-react";
import useFetchPost from "../../hooks/useFetchPost";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import geoJson from "./world.json";
import * as echarts from "echarts";

const SalesPerState: React.FC = props => {
	const { bodyData } = props;
	const [salesPerYearData, setSalesPerYearData] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	console.log(bodyData);
	const endpoint = process.env.REACT_APP_API_URL + "reports/getSalesPerState";

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.post(endpoint, bodyData, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				console.log(response.data.data);
				setSalesPerYearData(response.data.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [bodyData]);

	//if (loading)
	//return <span className="loading loading-spinner loading-lg"></span>;

	const onChartClick = params => {
		console.log("Chart clicked", params);
	};

	const onEvents = {
		click: onChartClick,
	};

	const data = [
		{ name: "Jalisco", value: 456 },
		{ name: "Nuevo León", value: 555 },
		{ name: "Zacatecas", value: 66 },
		{ name: "Guerrero", value: 23 },
	];

	var type = " $";

	if (bodyData.courtesy) {
		type = " Qty";
	}

	echarts.registerMap("Mexico", geoJson);

	const options = {
		grid: { top: 8, right: 8, bottom: 24, left: 68 },

		visualMap: {
			left: "right",
			min: 0,
			max: 450000,
			inRange: {
				color: [
					"#313695",
					"#4575b4",
					"#74add1",
					"#abd9e9",
					"#e0f3f8",
					"#ffffbf",
					"#fee090",
					"#fdae61",
					"#f46d43",
					"#d73027",
					"#a50026",
				],
			},
			text: ["High", "Low"],
			calculable: true,
		},
		tooltip: {
			trigger: "item",
			showDelay: 0,
			transitionDuration: 0.2,
		},
		toolbox: {
			feature: {
				dataView: { show: true, readOnly: false },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},

		series: [
			{
				id: "population",
				type: "map",
				roam: true,
				map: "Mexico",
				name: "Reports" + type + ":",

				animationDurationUpdate: 1000,
				universalTransition: true,
				data: salesPerYearData,
			},
		],
	};

	return <ReactECharts option={options} style={{ height: "500px" }} />;
};

export default SalesPerState;
