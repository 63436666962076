import { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import "./print.css"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


export default function Pdf(props) {
    const { fistPage, file, printRef } = props
    const [numPages, setNumPages] = useState(null);
    const containerRef = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.offsetWidth - 20);
        }
        const handleResize = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - 20);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const printErrorPdf = (e) => {
        alert(e)
    }

    return (

        <div ref={printRef} id="printable-area">
            <div ref={containerRef} className='w-full'>
            <Document
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={printErrorPdf}
                file={file}
            >
                {Array.from(
                    new Array(fistPage ? 1 : numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={width}
                        />
                    )
                )}
            </Document>
            </div>

        </div>

    );
}