import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import { useState } from "react";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";

function UserView() {
	console.log(localStorage.getItem("userId"));
	const userName = localStorage.getItem("userName");
	const userId = parseInt(localStorage.getItem("userId"));
	const [userSelected, setUserSelected] = useState({
		id: [userId],
		name: [userName],
	});
	const [dateSelected, setDateSelected] = useState();
	const [refresh, setRefresh] = useState(false);
	const [presetEvent, setPresetEvent] = useState({
		title: "",
		color: "#0991b2",
	});
	/*const [userSelected, setUserSelected] = useState({
		id: [],
		name: "",
	});*/
	console.log(userSelected.id.length);
	const changeTab = index => {
		console.log(index);
		setUserSelected(index);
	};

	const changeDate = index => {
		console.log(index);
		setDateSelected(index.dateStr);
	};

	const handleEventForm = async event => {
		event.preventDefault();

		const title = event.target.eventTitle.value;
		const description = event.target.eventDescription.value;
		const start = event.target.eventStart.value;
		const end = event.target.eventEnd.value;
		const color = event.target.eventColor.value;
		const userId = event.target.userId.value;

		if (title !== "" || start !== "" || end !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "events";
			try {
				const response = await axios.post(
					endpoint,
					{
						title: title,
						description: description,
						start: start,
						end: end,
						allDay: true,
						backgroundColor: color.slice(1),
						borderColor: color.slice(1),
						createdBy: localStorage.getItem("userId"),
						userId: userId,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}

		console.log(title, description, start, end);

		//const userEmail = event.target.userEmail.value;
	};

	return (
		<>
			<div
				tabIndex={0}
				className="bg-base-100 w-full p-1 rounded-lg mb-5 collapse collapse-arrow"
			>
				<input type="checkbox" />
				<div className="collapse-title text-xl font-medium">
					<p>{userName} / Schedule Functions</p>
				</div>
				<div tabIndex={0} className="collapse-content">
					<>
						<div className="divider">Add Event</div>

						<form onSubmit={handleEventForm}>
							<div className="w-full flex">
								<div className="form-control">
									<label className="label cursor-pointer mr-5">
										<input
											type="radio"
											name="eventType"
											className="radio checked:bg-cyan-600 mr-2"
											value="Custom"
											checked
											onClick={() =>
												setPresetEvent({
													title: "",
													color: "#0991b2",
												})
											}
										/>
										<span className="label-text">
											Custom
										</span>
									</label>
								</div>
							</div>
							<div className="border-1 grid grid-cols-2 gap-6 p-2">
								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											Event Title
										</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full"
										name="eventTitle"
										value={presetEvent.title}
										onChange={e =>
											setPresetEvent({
												title: e.target.value,
											})
										}
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											Event Description / Additional Info
										</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="eventDescription"
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											Start Date
										</span>
									</label>
									<input
										type="date"
										placeholder="Type here"
										className="input input-bordered w-full "
										value={dateSelected}
										onChange={e =>
											setDateSelected(e.target.value)
										}
										name="eventStart"
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											End Date
										</span>
									</label>
									<input
										type="date"
										placeholder="Type here"
										className="input input-bordered w-full"
										name="eventEnd"
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											Event Color
										</span>
									</label>
									<input
										type="color"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="eventColor"
										disabled
										value={
											presetEvent
												? presetEvent.color
												: "#000000"
										}
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">
											User Id
										</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full"
										value={userId}
										name="userId"
										disabled
									/>
								</div>
							</div>
							<button
								type="submit"
								className="btn btn-wide mt-4 mr-2"
							>
								SAVE
							</button>
						</form>
					</>
				</div>
			</div>

			<div className="flex flex-row bg-base-100 p-6 rounded-lg">
				<UserList changeTab={changeTab} usersSelected={userSelected} />
				{userSelected.id.length > 0 ? (
					<ScheduleUser
						userIdSelected={userSelected.id}
						userId={userId}
						changeDate={changeDate}
						refresh={refresh}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default UserView;
