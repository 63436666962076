
import { format, subMonths } from 'date-fns';
import { dateFormatDB } from './constants';


export const moneyKHandle = (money) => {
    //const moneyConvert = money > 1000 ? `${(money/1000).toFixed(1)}K` : `${money.toFixed(1)}`;
    const moneyConvert = `${money.toFixed(2)}`;
    return moneyConvert
}

export const getUserData = () => {
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");
    const organizationId = localStorage.getItem("organizationId");
    const userId = localStorage.getItem("userId");
    const isAdmin = (localStorage.getItem("expenseAdmin") === 'true');
    const isUserViewerAdmin = (localStorage.getItem("userviewerAdmin") === 'true');
    return { userName, userEmail, userId, isAdmin, organizationId, isUserViewerAdmin };
}

export const cardsBuilder = (CardView, cardsInfo, isAdmin, dashboardRequest) => {
    const slicedArray = cardsInfo.slice(0, 50);
    return (
        <div class="grid grid-flow-row auto-rows-max gap-6 lg:grid-cols-3">
            {
                slicedArray.map((card, index) =>
                    <CardView
                        key={index}
                        cardData={card}
                        isAdmin={isAdmin}
                        dashboardRequest={dashboardRequest}
                    />)
            }
        </div>
    );
}

export function searchBody(userId = 0, userName = "ALL", from = new Date(), to = new Date(),
    dateTime = new Date(), principal = "ALL", status = "ALL",
    expenseType = "ALL", budgetType = "ALL", clientName = "ALL") {
    return {
        "user_id": userId,
        "user_name": userName,
        "datetime": dateTime,
        "from": from,
        "to": to,
        "principal": principal,
        "status": status,
        "expense_type": expenseType,
        "budget_type": budgetType,
        "client_name": clientName
    }
}

export const sumValuesByProperty = (data, property) => {
    const wQty = data.reduce((accumulator, currentValue) =>
        accumulator + parseFloat(currentValue[property]), 0,
    );
    return wQty;
}
export const sumRowsByProperty = (data, property, name) => {
    const filter = data.filter((row) => row[property] === name);
    const wQty = filter.reduce((accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.amount), 0,
    );
    return wQty;
}
export const countRowsByProperty = (data, property, name) => {
    const filter = data.filter((row) => row[property] === name);
    return filter.length;
}

const subSumTypes = (data, typeName, keyName) => {
    const filter = data.filter((row) => row[keyName] === typeName);
    const sum = filter.reduce((accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.amount), 0,
    );
    return parseInt(sum)
}
export const sumTypesExpenses = (data, keyName) => {
    const extractTypes = data.map((row) => row[keyName])
    const deleteDuplicates = [...new Set(extractTypes)]
    const sumValues = deleteDuplicates.map((type) => subSumTypes(data, type, keyName))

    if (deleteDuplicates.length) {
        return {
            "keys": deleteDuplicates,
            "values": sumValues
        };
    }
    else {
        return {
            "keys": ["None"],
            "values": [0]
        };
    }
}
export const progressValue = (data, property, name) => {
    const sumAut = moneyKHandle(sumRowsByProperty(data, property, name))
    const sumAll = moneyKHandle(sumValuesByProperty(data, "amount"))
    const porcent = (sumAut * 100) / sumAll
    return porcent
}

export const progressSpentMoney = (dataBudget, dataInvoice, property, name) => {
    const sumBudget = moneyKHandle(sumRowsByProperty(dataBudget, property, name))
    const sumInvoice = moneyKHandle(sumRowsByProperty(dataInvoice, property, name))
    if (sumBudget === "0.00") {
        return 0

    }
    else {
        return ((sumInvoice * 100) / sumBudget)
    }
}

export const monthDateWithThreshold = (datetime, threshold) => {
    const to_obj = datetime.setDate(threshold);
    const from_obj = subMonths(to_obj, 1);
    const from = format(from_obj, dateFormatDB);
    const to = format(to_obj, dateFormatDB);
    
    return { from, to, from_obj , to_obj};
}

export const getUserByLevelHandle = (data, levelNum) => {
    const levelFilter = data.filter((user) => user["org_chart_level"] === levelNum);
    return levelFilter
}
export const getUserByLevelAndSuperiorHandle = (data, levelNum, user_id) => {
    const levelFilter = data.filter((user) => user["org_chart_level"] === levelNum && user["user_superior_id"] === user_id);
    return levelFilter
}

export const handleImage = (data) => {
    const file2 = new Blob([new Uint8Array(data)]);
    const newUrl = URL.createObjectURL(file2);
    return newUrl
}

export function separator(numb) {
    var str = numb.toString().split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str.join('.');
}

/* Function to generate combination of password */
export function generatePass() {
    let pass = '';
    let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789@#$';

    for (let i = 1; i <= 12; i++) {
        let char = Math.floor(Math.random()
            * str.length + 1);

        pass += str.charAt(char)
    }

    return pass;
}


