import BasicSelector from '../inputs/BasicSelector';
import MonthPicker from '../inputs/MonthPicker';
import { monthDateWithThreshold } from '../../utils/utils';
import { useTypeKey } from '../../hooks/useTypeKey';
export default function FilterBudgetSearch(props) {
    const { show, handleForm, isAdmin, filterValues, setFilterValues, dayThreshold } = props;

    const dateHandle = (dt) => {
        const { from, to } = monthDateWithThreshold(dt, dayThreshold)
        setFilterValues({
            ...filterValues,
            "datetime": dt,
            "from": from,
            "to": to
        })
    }
    const selectorHandle = (keyName, value) => {
        setFilterValues({
            ...filterValues,
            [keyName]: value,
        })
    }
    const usersData = JSON.parse(sessionStorage.getItem("users"));
    const principalsData = JSON.parse(sessionStorage.getItem("principals"));
    const statusData = JSON.parse(sessionStorage.getItem("status"));
    const clientsData = JSON.parse(sessionStorage.getItem("clients"));
    const typesData = JSON.parse(sessionStorage.getItem("budget_types"));

    const userSelectorHandle = (value) => {
        const uName = usersData.filter((row) => row.id === parseInt(value));
        if (uName.length === 1) {
            setFilterValues({
                ...filterValues,
                user_id: value,
                user_name: uName[0].name
            })
        }
        else {
            setFilterValues({
                ...filterValues,
                user_id: 0,
                user_name: "ALL"
            })
        }

    }
    const typeKeyHandle=()=>{
        handleForm(false);
    }

    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            {
                show &&
                (<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                        <h3 className="font-bold text-lg mb-4">Filter Search</h3>
                        <button type='button' className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                        <form>
                            {
                                isAdmin &&
                                (
                                    <div className="form-control mb-4">
                                        <label for="large-input" class="block mb-2 text-sm font-medium text-black">User</label>
                                        <select
                                            className="input input-bordered border-gray-400"
                                            value={filterValues.user_id}
                                            onChange={(e) => userSelectorHandle(e.target.value)}
                                            required
                                        >
                                            <option selected value={0}>ALL</option>
                                            {
                                                usersData.map((value, index) =>
                                                    <option key={index} value={value.id} label={value.name} />)
                                            }
                                        </select>
                                    </div>
                                )
                            }
                            <div className="mb-4">
                                <BasicSelector
                                    title="Principal"
                                    keyName="principal"
                                    data={principalsData}
                                    selectorHandle={selectorHandle}
                                    value={filterValues.principal}
                                    allowAll={true} />
                            </div>
                            <div className="mb-4">
                                <BasicSelector
                                    title="Status"
                                    keyName="status"
                                    data={statusData}
                                    selectorHandle={selectorHandle}
                                    value={filterValues.status}
                                    allowAll={true} />
                            </div>
                            <div className="mb-4">
                                <BasicSelector
                                    title="Client"
                                    keyName="client_name"
                                    data={clientsData}
                                    selectorHandle={selectorHandle}
                                    value={filterValues.client_name}
                                    allowAll={true} />
                            </div>
                            <div className="mb-4">
                                <BasicSelector
                                    title="Budget Type"
                                    keyName="budget_type"
                                    data={typesData}
                                    selectorHandle={selectorHandle}
                                    value={filterValues.budget_type}
                                    allowAll={true} />
                            </div>
                            <div className="mb-4">
                                <MonthPicker
                                    title="Select Month"
                                    setSelectMonth={dateHandle}
                                    selectMonth={filterValues.datetime} />
                            </div>
                        </form>
                    </div>
                </div>)
            }
        </>

    );
};

