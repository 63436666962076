import { useState} from 'react';

const useFetchClick = (url, options, dashboardRequest) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

	const handleRequest = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                url,
                {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(options),
                });
            if (!response.ok) {
                setError(`Error. Server NOT OK`);
            }
            else{
                setError(null);
                dashboardRequest();
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
	};

    return { loading, error, handleRequest};
};

export default useFetchClick;
