import { useEffect } from "react";
import UserOrgDoc from "./UserOrgDoc";
import useFetch from "../../hooks/useFetch";
import AlertMessage from "../ui/AlertMessage";
import LoadingBar from "../ui/LoadingBar";
import { getUserData } from "../../utils/utils";

export default function UserOrgDocs(props) {
    const { userInfo, setTabContent, isAdmin, dashboardRequest, handleForm } = props;
    const userLogin = getUserData();
    const { data, loading, error,
        handleRequest: handleRequestFilesExist } = useFetch(
            `${process.env.REACT_APP_API_URL}user_files_exist`,
            {
                "user_id": userInfo.id,
                "organization_id":userLogin.organizationId
            }
        )
    useEffect(() => {
        handleRequestFilesExist();
    }, [])

    return (
        <form onSubmit={e => { e.preventDefault(); }}>
            <LoadingBar loading={loading} style="mt-4"/>
            <AlertMessage error={error} style="mt-4"/>
            {
                data.map((fileField, index) =>
                    <UserOrgDoc 
                    handleForm={handleForm}
                    dashboardRequest={dashboardRequest}
                    isAdmin={isAdmin}
                    key={index} 
                    userId={userInfo.id} 
                    fileField={fileField} 
                    handleRequestFilesExist={handleRequestFilesExist}
                    setTabContent={setTabContent}/>
                )
            }
        </form>
    );
}