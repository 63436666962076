
export default function DiseasesTable({ setBodyFetch, bodyFetch }) {
    const deleteHandle = (key) => {
        const delVal = {
            ...bodyFetch.diseases
        }
        delete delVal[key];
        setBodyFetch({
            ...bodyFetch,
            "diseases": delVal,
        })
    }
    const editHandled = (key, val) => {
        const editVal = {
            ...bodyFetch.diseases,
            [key]: val
        }
        setBodyFetch({
            ...bodyFetch,
            "diseases": editVal,
        })
    }
    const getMaxKeyHandle = () => {
        const listKey = Object.keys(bodyFetch.diseases).map((val) => parseInt(val));
        if (!listKey.length) {
            return "1";
        }
        else {
            const maxVal = Math.max(...listKey) + 1;
            return maxVal.toString();
        }
    }
    return (
        <div className="form-control mb-4">
            <label class="block mb-2 text-sm font-medium text-black">Diseases
                <button
                    onClick={() => editHandled(getMaxKeyHandle(), "")}
                    type="button"
                    className="bg-green-400 rounded-lg px-2 ml-4">
                    Add New
                </button>
            </label>
            {Object.keys(bodyFetch.diseases).length ?
                (<div className="overflow-x-auto border-2 border-white">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(bodyFetch.diseases).map((key, ind) =>
                                    <tr key={ind}>
                                        <td>
                                            <input
                                                onChange={(e) => editHandled(key, e.target.value)}
                                                className="rounded-lg px-2"
                                                value={bodyFetch.diseases[key]} />
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => deleteHandle(key)}
                                                className="bg-white rounded-lg px-2">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>) : null
            }

        </div>
    );
}