import { useState } from 'react';

const useFetch = (url, options , defaultLoading=true) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(defaultLoading);
    const [error, setError] = useState(null);

	const handleRequest = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                url,
                {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(options),
                });
            if (!response.ok) {
                setError(`Error. Server NOT OK`);
            }
            const result = await response.json();
            setData(result);
            setError(null);
        } catch (err) {
            setError(err);
            setData([]);
        } finally {
            setLoading(false);
        }
	};

    return { data, loading, error, handleRequest };
};

export default useFetch;
