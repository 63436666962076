
import { useEffect, useState } from "react";

const getTimeDifference = (startDate, dayThreshold) => {
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    let endDate = new Date(startDate);
    endDate.setDate(dayThreshold + 1);
    endDate.setHours(0, 0, 0, 0);

    // Check if the 27th of this month is in the past, if so, move to the next month
    if (endDate <= startDate) {
        endDate.setMonth(endDate.getMonth() + 1);
    }

    const diff = endDate - startDate;

    const days = Math.floor(diff / msInDay);
    const hours = Math.floor((diff % msInDay) / msInHour);
    const minutes = Math.floor((diff % msInHour) / msInMinute);
    const seconds = Math.floor((diff % msInMinute) / msInSecond);

    return { days, hours, minutes, seconds };
};

const useTimeDifference = (dayThreshold) => {
    const startDate = new Date();
    const [timeDifference, setTimeDifference] = useState(() => getTimeDifference(startDate, dayThreshold));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeDifference(getTimeDifference(new Date(), dayThreshold));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startDate]);

    return timeDifference;
};

export default function CountDown(props) {
    const {dayThreshold} =props;
    const {days, hours, minutes, seconds} = useTimeDifference(dayThreshold);
    return (
        <div className="grid grid-flow-col gap-5 text-center auto-cols-max w-72 w-full">
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    <span style={{ "--value": days }}></span>
                </span>
                days
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    <span style={{ "--value": hours }}></span>
                </span>
                hours
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    <span style={{ "--value": minutes }}></span>
                </span>
                min
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    <span style={{ "--value": seconds }}></span>
                </span>
                sec
            </div>
        </div>
    );
}