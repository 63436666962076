import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import React, { useState, useEffect } from "react";
import SelectUsers from "../../components/form/selectUsers";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";
import SelectClients from "../../components/form/selectClients";

import sendEmail from "../../utils/sendEmail.js";

function TicketForm(props) {
	const { ticketId, refresh } = props;
	const [userAssigned, setUserAssigned] = useState({});
	const [clientSelected, setClientSelected] = useState();

	const tickets = localStorage.getItem("tickets");
	console.log(tickets);

	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "tickets/" + ticketId;
	const [data, setData] = useState(null);
	const [formLoading, setFormLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [errorType, setErrorType] = useState("");
	const [chargeable, setChargeable] = useState("");

	console.log(errorType);

	const [test, setTest] = useState(null);

	useEffect(() => {
		console.log("ready");
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data[0]);
				setChargeable(data.chargeable);
				console.log("ticket info: ", response.data.data[0]);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	if (ticketId && loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	const changeTab = index => {
		console.log(index);
		setClientSelected(index.id);
	};

	const changeUsers = index => {
		//console.log(index);
		setUserAssigned(index);
		console.log(userAssigned);
	};

	const handleEventForm = async event => {
		event.preventDefault();
		//event.target.subButton.disabled = true;
		var enterif = false;
		if (userAssigned != null) {
			enterif = !(
				Object.keys(userAssigned).length === 0 && userAssigned.constructor === Object
			);
		}
		if (enterif) {
			var arr = [];
			userAssigned.forEach(user => {
				arr.push(user.id);
			});

			console.log(arr);
		}

		const errorType = event.target.errorType.value;
		const date = event.target.date.value;
		const subject = event.target.subject.value;
		const salesforceNo = event.target.salesforceNo.value;
		const inquityType = event.target.inquityType.value;
		const equipmentStatus = event.target.equipmentStatus.value;
		const frecuencyOfOcurrence = event.target.frecuencyOfOcurrence.value;
		const errorCode = event.target.errorCode.value;
		const onSiteWorkHistory = event.target.onSiteWorkHistory.value;
		const problemDescription = event.target.problemDescription.value;
		const building = event.target.building.value;
		const line = event.target.line.value;

		if (errorType == "hardware") {
			const hardwareType = event.target.hardwareType.value;
			const machineType = event.target.machineType.value;
			const serialNo = event.target.serialNo.value;
			const machineAppVersion = event.target.machineAppVersion.value;
			var hsInfoData = {
				hardwareType: hardwareType,
				machineType: machineType,
				serialNo: serialNo,
				machineAppVersion: machineAppVersion,
			};
		} else if (errorType == "software") {
			const softwareType = event.target.softwareType.value;
			const version = event.target.version.value;
			var hsInfoData = {
				softwareType: softwareType,
				version: version,
			};
		}

		if (enterif) {
			const priority = event.target.priority.value;
			var reqBodyData = {
				errorType: errorType,
				date: date,
				subject: subject,
				salesforceNo: salesforceNo,
				userAssigned: arr,
				clientSelected: clientSelected,
				inquityType: inquityType,
				priority: priority,
				equipmentStatus: equipmentStatus,
				frecuencyOfOcurrence: frecuencyOfOcurrence,
				errorCode: errorCode,
				onSiteWorkHistory: onSiteWorkHistory,
				problemDescription: problemDescription,
				building: building,
				line: line,
				hsInfo: hsInfoData,

				createdBy: localStorage.getItem("userId"),
			};
		} else {
			var reqBodyData = {
				errorType: errorType,
				date: date,
				subject: subject,
				salesforceNo: salesforceNo,
				userAssigned: null,
				clientSelected: null,
				inquityType: inquityType,
				priority: null,
				equipmentStatus: equipmentStatus,
				frecuencyOfOcurrence: frecuencyOfOcurrence,
				errorCode: errorCode,
				onSiteWorkHistory: onSiteWorkHistory,
				problemDescription: problemDescription,
				building: building,
				line: line,
				hsInfo: hsInfoData,

				createdBy: localStorage.getItem("userId"),
			};
		}

		console.log(reqBodyData);

		if (date !== "" || inquityType !== "") {
			setFormLoading(true);
			const endpoint = process.env.REACT_APP_API_URL + "tickets";
			try {
				const response = await axios.post(endpoint, reqBodyData, {
					headers: {
						"Content-Type": "application/json",
					},
				});

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				event.target.reset(); //add this line
				setFormLoading(false);
				setRefetch(!refetch);
				/*const corList = [
					"alfredoplazola@pacmexico.com.mx",
					"danieljimenez@pacmexico.com.mx",
					"everardojaime@pacmexico.com.mx",
					"GilbertoVelazco@pacmexico.com.mx",
					"MarioChavez@pacmexico.com.mx",
					"OsbaldoRodriguez@pacmexico.com.mx",
					"osvaldosepulveda@pacmexico.com.mx",
					"RicardoRivera@pacmexico.com.mx",
					"carlosjaime@pacmexico.com.mx",
					"victor@pacmexico.com.mx",
				];*/
				const corList = ["carlosjaime@pacmexico.com.mx"];
				sendEmail({
					to: corList,
					subject:
						"New ticket #" +
						response.data.ticketId +
						", user: " +
						localStorage.getItem("userName"),
					body: "new ticket added, please set FSE and priority",
				});
			} catch (error) {
				console.log(error);
				toast.error(error);
				setFormLoading(!formLoading);
			}
		} else {
			console.log("error");
		}

		//const userEmail = event.target.userEmail.value;
	};

	const handleUpdateFSE = async event => {
		event.preventDefault();
		console.log(userAssigned);
		const priority = event.target.priority.value;

		if (userAssigned !== null && priority !== null) {
			const userId = userAssigned[0].id;
			const userName = userAssigned[0].name;
			const endpoint = process.env.REACT_APP_API_URL + "assignFSE/";
			try {
				const response = await axios.post(
					endpoint,
					{
						userId: userId,
						ticketId: ticketId,
						priority: priority,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("FSE and Priority Assigned!");
				setRefetch(!refetch);
				/*sendEmail({
					to: [userAssigned[0].email],
					subject: "Ticket #" + ticketId + " assign, priority " + priority,
					body: "Ticket assigned to you",
				});*/
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			toast.warning("Select FSE & Priority first");
		}
	};

	const handleUpdateCustomerInfo = async event => {
		event.preventDefault();
		console.log(clientSelected);
		const building = event.target.building.value;
		const line = event.target.line.value;
		const serviceReportNo = event.target.serviceReportNo.value;
		const salesforceNo = event.target.salesforceNo.value;

		if (clientSelected !== undefined) {
			const endpoint = process.env.REACT_APP_API_URL + "updateCustomerInfo";
			try {
				const response = await axios.post(
					endpoint,
					{
						customerId: clientSelected ? clientSelected : null,
						ticketId: ticketId ? ticketId : null,
						building: building ? building : null,
						line: line ? line : null,
						serviceReportNo: serviceReportNo ? serviceReportNo : null,
						salesforceNo: salesforceNo ? salesforceNo : null,
						chargeable: chargeable ? chargeable : null,
						userId: localStorage.getItem("userId"),
						userName: localStorage.getItem("userName"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("Customer Info updated!");
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			toast.warning("Select Customer first");
		}
	};

	return (
		<>
			{data !== null ? (
				<>
					<h2 class="card-title">FSE & Priority</h2>
					<div class="divider"></div>

					{data.user === null ? (
						<form onSubmit={handleUpdateFSE}>
							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Assign FSE</span>
								</label>
								<SelectUsers changeUsers={changeUsers} />
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Priority</span>
								</label>
								<select
									className="input input-bordered w-full"
									name="priority"
									defaultValue={data.priority}
								>
									<option value="low" label="low" />
									<option value="medium" label="medium" />
									<option value="high" label="high" />
								</select>
							</div>

							<button
								className="w-full btn btn-wide mt-4 mr-2 bg-accent"
								onSubmit={handleUpdateFSE}
							>
								ASSIGN
							</button>
						</form>
					) : (
						<form onSubmit={handleUpdateFSE}>
							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">FSE Assigned</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="fse"
									disabled
									defaultValue={data.user.name}
								/>
							</div>

							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Assign new FSE</span>
								</label>
								<SelectUsers changeUsers={changeUsers} />
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Priority</span>
								</label>
								<select
									className="input input-bordered w-full"
									name="priority"
									defaultValue={data.priority}
								>
									<option value="low" label="low" />
									<option value="medium" label="medium" />
									<option value="high" label="high" />
								</select>
							</div>

							<button
								className="w-full btn btn-wide mt-4 mr-2 bg-accent"
								onSubmit={handleUpdateFSE}
							>
								UPDATE
							</button>
						</form>
					)}

					<form onSubmit={handleUpdateCustomerInfo}>
						<h2 class="card-title mt-6">Customer Info</h2>
						<div class="divider"></div>

						{data.client === null ? (
							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Select Customer Name</span>
								</label>
								<SelectClients changeTab={changeTab} />
							</div>
						) : (
							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Customer</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="customer"
									disabled
									defaultValue={data.client.name}
								/>
							</div>
						)}

						<div className="form-control w-full">
							<label className="label">
								<span className="label-text">Building / Plant</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="building"
								defaultValue={data.building}
								//disabled={data.client === null ? "" : "disabled"}
							/>
						</div>
						<div className="form-control w-full">
							<label className="label">
								<span className="label-text">Line</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="line"
								defaultValue={data.line}
								//disabled={data.client === null ? "" : "disabled"}
							/>
						</div>

						<div className="form-control w-full">
							<label className="label">
								<span className="label-text">Service Report #</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="serviceReportNo"
								defaultValue={data.serviceReportNo}
								//disabled={data.client === null ? "" : "disabled"}
							/>
						</div>

						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Chargeable ?</span>
								<input
									type="checkbox"
									className="checkbox"
									name="chargeable"
									onChange={() => setChargeable(!chargeable)}
									checked={chargeable === true ? "checked" : ""}
								/>
							</label>
						</div>

						<div className="bform-control w-full">
							<label className="label">
								<span className="label-text">Salesforece (PAC/FAC only)</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="salesforceNo"
								defaultValue={data.saleforceNo}
								//disabled={data.client === null ? "" : "disabled"}
							/>
						</div>

						<button
							className="w-full btn btn-wide mt-6 mr-2 bg-accent"
							onSubmit={handleUpdateCustomerInfo}
						>
							UPDATE
						</button>
					</form>

					<form onSubmit={handleEventForm}>
						<h2 class="card-title mt-6">Ticket Info</h2>
						<div class="divider"></div>

						<div class="form-control">
							<label class="label cursor-pointer">
								<span class="label-text">Hardware</span>
								<input
									type="radio"
									name="errorType"
									class="radio checked:bg-red-500"
									value="hardware"
									onChange={() => setErrorType("hardware")}
									checked={data.errorType === "hardware"}
								/>
							</label>
						</div>
						<div class="form-control">
							<label class="label cursor-pointer">
								<span class="label-text">Software</span>
								<input
									type="radio"
									name="errorType"
									class="radio checked:bg-blue-500"
									value="software"
									onChange={() => setErrorType("software")}
									checked={data.errorType === "software"}
								/>
							</label>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Subject</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="subject"
								defaultValue={data.subject}
								required
							/>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Date</span>
							</label>
							<input
								type="date"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="date"
								defaultValue={data.date.slice(0, 10)}
								required
							/>
						</div>

						<div className="form-control w-full">
							<label className="label">
								<span className="label-text">Inquiry Type</span>
							</label>
							<select
								className="input input-bordered w-full"
								name="inquityType"
								default={data.inquiryType}
								required
							>
								<option value="recovered" label="Host error (Nexim/Flexa/Trax/Accessory)" />
								<option
									value="recovered"
									label="Other error (Application/Services/Image processing)"
								/>
								<option
									value="recovered"
									label="Damage/malfunction of mechanical/electrical parts"
								/>
								<option value="recovered" label="Interference" />
								<option value="recovered" label="Poor mounting accuracy/missing parts" />
								<option value="recovered" label="Wrong parts places" />

								<option value="recovered" label="Module/machine failed to place any parts" />
								<option value="recovered" label="Panel transfer error" />
								<option value="recovered" label="Other trouble" />
								<option value="recovered" label="Question" />
								<option value="recovered" label="Request" />
								<option value="recovered" label="Knowledge posting request" />
							</select>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Equipment Status</span>
							</label>
							<select
								className="input input-bordered w-full"
								name="equipmentStatus"
								defaultValue={data.equipmentStatus}
							>
								<option value="no stop" label="no stop" />
								<option value="intermittent stop" label="intermittent stop" />
								<option value="breakdown mode" label="breakdown mode" />
								<option value="completely stopped" label="completely stopped" />
								<option value="recovered" label="recovered" />
							</select>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Frequency Of Ocurrance</span>
							</label>
							<select
								className="input input-bordered w-full"
								name="frecuencyOfOcurrence"
								defaultValue={data.frecuencyOfOcurrence}
							>
								<option value="every time" label="every time" />
								<option value="several times a day" label="several times a day" />
								<option value="several times a week" label="several times a week" />
								<option value="several times a month" label="several times a month" />
								<option value="once so far" label="once so far" />
								<option value="several times so far" label="several times so far" />
							</select>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Error Code</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="errorCode"
								defaultValue={data.errorCode}
							/>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">On-site Work History</span>
							</label>
							<textarea
								class="textarea textarea-bordered h-24"
								placeholder="Type here"
								name="onSiteWorkHistory"
								defaultValue={data.onSiteWorkHistory}
							></textarea>
						</div>

						<div className="form-control w-full ">
							<label className="label">
								<span className="label-text">Problem Description</span>
							</label>
							<textarea
								class="textarea textarea-bordered h-24"
								placeholder="Type here"
								name="problemDescription"
								defaultValue={data.problemDescription}
							></textarea>
						</div>

						<div className={data.errorType !== "hardware" ? "hidden" : ""}>
							<div className="divider w-full mt-10">Hardware Info</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Nexim</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.hardwareType === "nexim"}
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Flexa</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.hardwareType === "flexa"}
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Trax</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.hardwareType === "trax"}
									/>
								</label>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Machine type</span>
								</label>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Serial No</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="serialNo"
									defaultValue={data.hsInfo.serialNo}
								/>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Machine app version</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="machineAppVersion"
									defaultValue={data.hsInfo.machineAppVersion}
								/>
							</div>
						</div>

						<div className={data.errorType !== "software" ? "hidden" : ""}>
							<div className="divider w-full mt-10">Software Info</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Nexim</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.softwareType === "nexim"}
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Flexa</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.softwareType === "flexa"}
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Trax</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked={data.hsInfo.softwareType === "trax"}
									/>
								</label>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Version</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="version"
									defaultValue={data.hsInfo.version}
								/>
							</div>
						</div>

						<button type="submit" className="w-full btn btn-wide mt-4 mr-2 bg-accent">
							UPDATE
						</button>
					</form>
				</>
			) : (
				<div className="w-full flex justify-center">
					<form
						onSubmit={handleEventForm}
						className="card bg-base-100 p-6 w-full lg:w-3/5"
					>
						<div
							className={`bform-control w-full mb-5ox ${tickets === "user" ? "hidden" : ""}`}
						>
							<div className="divider w-full">FSE & Priority</div>

							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Assign FSE</span>
								</label>
								<SelectUsers changeUsers={changeUsers} />
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Priority</span>
								</label>
								<select className="input input-bordered w-full" name="priority">
									<option value="low" label="low" />
									<option value="medium" label="medium" />
									<option value="high" label="high" />
								</select>
							</div>
						</div>

						<div
							className={`bform-control w-full mb-5ox ${tickets === "user" ? "hidden" : ""}`}
						>
							<div className="divider w-full mt-10">Customer Info</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Select Customer</span>
								</label>
								<SelectClients changeTab={changeTab} />
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Building / Plant</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="building"
								/>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Line</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="line"
								/>
							</div>
							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Service Report #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="serviceReportNo"
									//disabled={data.client === null ? "" : "disabled"}
								/>
							</div>

							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Chargeable ?</span>
									<input
										type="checkbox"
										className="checkbox"
										name="chargeable"
										onChange={() => setChargeable(!chargeable)}
										checked={chargeable === true ? "checked" : ""}
									/>
								</label>
							</div>

							<div className="bform-control w-full">
								<label className="label">
									<span className="label-text">Salesforece (PAC/FAC only)</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="salesforceNo"
									//disabled={data.client === null ? "" : "disabled"}
								/>
							</div>
						</div>

						<div className="divider w-full">Error Type</div>

						<div class="form-control">
							<label class="label cursor-pointer">
								<span class="label-text">Hardware</span>
								<input
									type="radio"
									name="errorType"
									class="radio checked:bg-red-500"
									value="hardware"
									onChange={() => setErrorType("hardware")}
									checked={errorType === "hardware"}
									required
								/>
							</label>
						</div>
						<div class="form-control">
							<label class="label cursor-pointer">
								<span class="label-text">Software</span>
								<input
									type="radio"
									name="errorType"
									class="radio checked:bg-blue-500"
									value="software"
									onChange={() => setErrorType("software")}
									checked={errorType === "software"}
									required
								/>
							</label>
						</div>

						<div className="divider w-full mt-10">Error Info</div>
						{/*<div className="form-control w-full mb-5">  border-1 grid grid-cols-2 gap-6 p-2
						<label className="label">
							<span className="label-text">Date</span>
						</label>
						<input
							type="date"
							placeholder="Type here"
							className="input input-bordered w-full"
							name="date"
						/>
					</div>

					<div className="form-control w-full mb-5">
						<label className="label">
							<span className="label-text">User Assigned</span>
						</label>
						<SelectUsers changeUsers={changeUsers} />
					</div>

					<div className="form-control w-full mb-5">
						<label className="label">
							<span className="label-text">Select ClientName</span>
						</label>
						<SelectClients changeTab={changeTab} />
			</div>*/}
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Subject</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="subject"
								required
							/>
						</div>

						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Date</span>
							</label>
							<input
								type="date"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="date"
								required
							/>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Inquiry Type</span>
							</label>
							<select className="input input-bordered w-full" name="inquityType" required>
								<option value="recovered" label="Host error (Nexim/Flexa/Trax/Accessory)" />
								<option
									value="recovered"
									label="Other error (Application/Services/Image processing)"
								/>
								<option
									value="recovered"
									label="Damage/malfunction of mechanical/electrical parts"
								/>
								<option value="recovered" label="Interference" />
								<option value="recovered" label="Poor mounting accuracy/missing parts" />
								<option value="recovered" label="Wrong parts places" />

								<option value="recovered" label="Module/machine failed to place any parts" />
								<option value="recovered" label="Panel transfer error" />
								<option value="recovered" label="Other trouble" />
								<option value="recovered" label="Question" />
								<option value="recovered" label="Request" />
								<option value="recovered" label="Knowledge posting request" />
							</select>
						</div>

						{/*<div className="form-control w-full mb-5">
						<label className="label">
							<span className="label-text">Priority</span>
						</label>
						<select className="input input-bordered w-full" name="priority">
							<option value="low" label="low" />
							<option value="medium" label="medium" />
							<option value="high" label="high" />
						</select>
					</div>
					*/}
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Equipment Status</span>
							</label>
							<select className="input input-bordered w-full" name="equipmentStatus" required>
								<option value="no stop" label="no stop" />
								<option value="intermittent stop" label="intermittent stop" />
								<option value="breakdown mode" label="breakdown mode" />
								<option value="completely stopped" label="completely stopped" />
								<option value="recovered" label="recovered" />
							</select>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Frequency Of Ocurrance</span>
							</label>
							<select
								className="input input-bordered w-full"
								name="frecuencyOfOcurrence"
								required
							>
								<option value="every time" label="every time" />
								<option value="several times a day" label="several times a day" />
								<option value="several times a week" label="several times a week" />
								<option value="several times a month" label="several times a month" />
								<option value="once so far" label="once so far" />
								<option value="several times so far" label="several times so far" />
							</select>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Error Code</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="errorCode"
								pattern="[a-zA-Z0-9]*"
								minlength="8"
								maxlength="8"
								required
							/>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">On-site Work History</span>
							</label>
							<textarea
								class="textarea textarea-bordered h-24"
								placeholder="Type here"
								name="onSiteWorkHistory"
								required
							></textarea>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Problem Description</span>
							</label>
							<textarea
								class="textarea textarea-bordered h-24"
								placeholder="Type here"
								name="problemDescription"
								required
							></textarea>
						</div>

						<div className={errorType !== "hardware" ? "hidden" : ""}>
							<div className="divider w-full mt-10">Hardware Info</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Nexim</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked
										value="nexim"
										required
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Flexa</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked
										value="flexa"
										required
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Trax</span>
									<input
										type="radio"
										name="hardwareType"
										className="radio checked:bg-blue-500"
										checked
										value="trax"
										required
									/>
								</label>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Machine type</span>
								</label>
								<select className="input input-bordered w-full" name="machineType" required>
									<option value="NXT" label="NXT" />
									<option value="NXTII" label="NXTII" />
									<option value="NXTIIc" label="NXTIIc" />
									<option value="NXTIII" label="NXTIII" />
									<option value="NXTIIIc" label="NXTIIIc" />
									<option value="NXTH" label="NXTH" />
									<option value="NXTHW" label="NXTHW" />
									<option value="NXTP" label="NXTP" />
									<option value="NXTR-S" label="NXTR-S" />
									<option value="NXTR-A" label="NXTR-A" />
									<option value="NXTR-PM" label="NXTR-PM" />

									<option value="" label="" disabled />

									<option value="AIMEX" label="AIMEX" />
									<option value="AIMEXII" label="AIMEXII" />
									<option value="AIMEXIIS" label="AIMEXIIS" />
									<option value="AIMEXIII" label="AIMEXIII" />
									<option value="AIMEXIIIc" label="AIMEXIIIc" />
									<option value="AIMEXR" label="AIMEXR" />

									<option value="" label="" disabled />

									<option value="GPX" label="GPX" />
									<option value="GPX-C" label="GPX-C" />
									<option value="GPX-CL" label="GPX-CL" />

									<option value="" label="" disabled />

									<option value="XP141" label="XP141" />
									<option value="XP241" label="XP241" />
									<option value="XPF" label="XPF" />

									<option value="" label="" disabled />

									<option value="sFAB50" label="sFAB50" />
									<option value="sFABD" label="sFABD" />
									<option value="sFABa" label="sFABa" />
									<option value="sFABsh" label="sFABsh" />
									<option value="SMART WING BA" label="SMART WING BA" />
									<option value="STOWER" label="STOWER" />

									<option value="" label="" disabled />

									<option value="SNC" label="SNC" />
									<option value="AHC" label="AHC" />
									<option value="AFMU" label="AFMU" />
									<option value="FEEDER JIG" label="FEEDER JIG" />

									<option value="" label="" disabled />

									<option value="TUM" label="TUM" />
									<option value="TUL" label="TUL" />
									<option value="TULT" label="TULT" />
									<option value="TULT2" label="TULT2" />
									<option value="TULTC" label="TULTC" />

									<option value="" label="" disabled />

									<option value="TULTW" label="TULTW" />
									<option value="TULTW2" label="TULTW2" />

									<option value="" label="" disabled />

									<option value="Single Stick Feeder" label="Single Stick Feeder" />
									<option value="Belt Type Stick Feeder" label="Belt Type Stick Feeder" />
									<option value="Tray Feeder M" label="Tray Feeder M" />
									<option value="Tray Feeder L" label="Tray Feeder L" />
									<option value="Radial Tape Feeder" label="Radial Tape Feeder" />
									<option value="Axial Tape Feeder" label="Axial Tape Feeder" />
									<option value="Vibratory Stick Feeder" label="Vibratory Stick Feeder" />
									<option
										value="Single Vibratory Stick Feeder"
										label="Single Vibratory Stick Feeder"
									/>

									<option value="" label="" disabled />

									<option value="CP4" label="CP4" />
									<option value="CP6" label="CP6" />
									<option value="CP643" label="CP643" />
									<option value="CP6500" label="CP6500" />
									<option value="CP743" label="CP743" />
									<option value="CP843" label="CP843" />
								</select>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Serial No</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="serialNo"
								/>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Machine app version</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="machineAppVersion"
								/>
							</div>
						</div>

						<div className={errorType !== "software" ? "hidden" : ""}>
							<div className="divider w-full mt-10">Software Info</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Nexim</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked
										value="nexim"
										required
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Flexa</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked
										value="flexa"
										required
									/>
								</label>
							</div>
							<div className="form-control">
								<label className="label cursor-pointer">
									<span className="label-text">Trax</span>
									<input
										type="radio"
										name="softwareType"
										className="radio checked:bg-blue-500"
										checked
										value="trax"
										required
									/>
								</label>
							</div>
							<div className="form-control w-full mb-5">
								<label className="label">
									<span className="label-text">Version</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="version"
								/>
							</div>
						</div>
						<button
							type="submit"
							name="subButton"
							className="btn btn-wide mt-4 mr-2 bg-accent"
							disabled={formLoading ? "disabled" : ""}
						>
							<span className={`${formLoading ? "hidden" : ""}`}>SAVE</span>
							<span
								className={`loading loading-spinner ${!formLoading ? "hidden" : ""}`}
							></span>
						</button>
					</form>
				</div>
			)}
		</>
	);
}

export default TicketForm;
