export default function TabSelector(props) {
    const { tabContent, setTabContent, mainStyle, isAdmin } = props;
    const TabButton = (name) => {
        return <div
            onClick={() => setTabContent(name)}
            className={tabContent !== name ? "rounded-t-lg bg-blue-100" : "rounded-t-lg bg-blue-300"}>{name}</div>
    }
    return (
        <>
            {isAdmin ?
                <div className={mainStyle}>
                    {TabButton("INFO")}
                    {TabButton("EDIT")}
                    {TabButton("DOCS")}
                </div> :
                <div className={mainStyle}>
                    {TabButton("INFO")}
                    {TabButton("DOCS")}
                </div>
            }
        </>


    );

}