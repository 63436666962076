
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { sumTypesExpenses, separator } from '../../utils/utils';
import useFetch from '../../hooks/useFetch';
import AlertMain from '../ui/AlertMain';
const chartOptions = (values) => {
    return {
        series: values["values"],
        chart: {
            height: 320,
            width: "100%",
            type: "donut",
        },
        colors: ["#393939", "#aeaeae", "#ec00ff", "#8d00ff", "#0007ff", "#00078c", "#007bff",
            "#00ffe5", "#38ff00", "#f6ff00", "#ff6a00", "#ff0000"],
        stroke: {
            colors: ["transparent"],
            lineCap: "",
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontFamily: "Inter, sans-serif",
                            offsetY: 20,
                        },
                        total: {
                            showAlways: true,
                            show: true,
                            label: "TOTAL",
                            fontFamily: "Inter, sans-serif",
                            formatter: function (w) {
                                const sum = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return '$' + separator(sum)
                            },
                        },
                        value: {
                            show: true,
                            fontFamily: "Inter, sans-serif",
                            offsetY: -20,
                            fontSize: '30px',
                            formatter: function (value) {
                                return value
                            },
                        },
                    },
                    size: "70%",
                },
            },
        },
        labels: values["keys"],
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: "bottom",
            fontFamily: "Inter, sans-serif",
        },
        grid: {
            show: false,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: 18,
                bottom: 0,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return separator(value)
                },
            },
        },
        xaxis: {
            labels: {
                formatter: function (value) {
                    return separator(value)
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
    }
}

export default function TypeRadialChart(props) {
    const { filterValues, url, keyName, title } = props;
    const { data, loading, error,handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}${url}`,
        filterValues
    )
    useEffect(() => {
        handleRequest();
    },[filterValues])
    const options = chartOptions(sumTypesExpenses(data, keyName));
    return (
        <div className='w-full bg-white rounded-xl mt-6 px-2 lg:px-10 pt-6 pb-6 shadow'>

            <div className="flex justify-center items-center">
                <h5 className="text-xl font-bold leading-none text-gray-900 pe-1">{title}</h5>
            </div>
            <AlertMain error={error} text={`Error! ${url}.`}/>
            {
                loading ?
                <div className='flex flex-col items-center justify-center'>
                    <span className="loading loading-dots loading-lg mb-4 w-20 mt-2"></span>
                </div> :
                <ReactApexChart
                    options={options}
                    series={options.series}
                    type="donut"
                    height={350}
                />
            }
        </div>
    );
}