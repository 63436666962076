import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import OrganizationChart from "../../components/charts/OrganizationChart";
import OrganizationWall from "../../components/charts/OrganizationWall";
import { getUserData } from "../../utils/utils";

export default function UsersViewer(props) {
    const userInfo = getUserData();
    const [orgId, setOrgId] = useState(userInfo.organizationId);

    const { data: dataChart, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}organization_chart`,
        {
            "organization_id": orgId
        }
    )

    useEffect(() => {
        handleRequest();
    }, [orgId])
    return (
        <div>

            <OrganizationWall orgId={orgId} setOrgId={setOrgId} dashboardRequest={handleRequest} isAdmin={userInfo.isUserViewerAdmin}/>
            <OrganizationChart
                isAdmin={userInfo.isUserViewerAdmin}
                sessionUserId = {userInfo.userId}
                dataChart={dataChart}
                orgId={orgId}
                loading={loading}
                dashboardRequest={handleRequest} />
        </div>
    );
}