import useFetchPost from "../../hooks/useFetchPost";
import React, { useState, useEffect } from "react";
import axios from "axios";

function VacationList(props) {
	const { changeTab, userId, userName, refresh } = props;
	console.log(props);
	const [isActive, setIsActive] = useState(false);
	const [idActive, setIdActive] = useState(userId);
	const currentYear = new Date().getFullYear();
	console.log(idActive);
	var currentArea = "";

	const endpoint = process.env.REACT_APP_API_URL + "events/eventType";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	useEffect(() => {
		setLoading(true);
		axios
			.post(
				endpoint,
				{
					userId: userId,
					eventType: "vacation",
					year: currentYear,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then(response => {
				setData(response.data);
				console.log(response.data);
				console.log(data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [JSON.stringify(userId), refresh]);

	return (
		<div className="max-auto grid grid-cols-2 gap-6 p-2 mt-5 ">
			<div className="bg-base-100 rounded-lg stats-vertical shadow">
				<div className="p-5 text-center text-xl font-bold">Events {currentYear}</div>
				<div className="max-h-96 overflow-y-auto">
					{data &&
						userId.length > 0 &&
						data.events.map((event, index) => (
							<div className="stat px-5 py-0 mb-2">
								<div
									className={
										event.backgroundColor == "0991b2"
											? "stat-title text-lg text-cyan-600"
											: event.backgroundColor == "94a3b8"
											? "stat-title text-lg text-slate-400"
											: "stat-title text-lg"
									}
								>
									{event.title} ({userName[userId.indexOf(event.userId)]}, {event.daysnumber} d)
								</div>
								{event.description !== "NULL" ? <p>{event.description}</p> : <></>}
								<div className="stat-desc text-lg">
									{event.start} / {event.end}
								</div>
							</div>
						))}
				</div>
			</div>
			<div className="max-auto bg-base-100 rounded-lg stats-vertical shadow">
				<div className="p-5 text-center text-xl font-bold">Vacations {currentYear}</div>
				<div className="max-h-96 overflow-y-auto">
					{data &&
						userId.length > 0 &&
						data.vacations.map((vacation, index) => (
							<div className="stat px-5 py-0 mb-2">
								<div
									className={
										vacation.backgroundColor == "fcd34d"
											? "stat-title text-lg text-amber-300"
											: "stat-title text-lg"
									}
								>
									{vacation.title} {vacation.daysnumber} days,{" "}
									{userName[userId.indexOf(vacation.userId)]}
								</div>
								<div className="stat-desc text-lg">
									{vacation.start} / {vacation.end}
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
}

export default VacationList;
