import React, {useEffect} from "react";
import { Chart } from "react-google-charts";
import useFetch from '../../hooks/useFetch';
import AlertMain from "../ui/AlertMain";

export const options = {
    region: "MX", //US   MX
    resolution: 'provinces',
    displayMode: 'markers',
    colorAxis: { colors: ['green', 'red'] }
};
export const mapConvertData = (data) => {
    const mapBody = data.map((row) =>
        [
            parseFloat(row.user_latitude),
            parseFloat(row.user_longitude),
            row.user_city,
            parseFloat(row.amount)
        ]
    )
    const mapHead = [['Latitud', 'Longitud', "City", 'Money']]
    const mapData = mapHead.concat(mapBody)
    return mapData

}
export default function GeoExpenseChart(props) {
    const { filterValues } = props;
    const { data, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}month_expenses_map`,
        filterValues
    )
    useEffect(() => {
        handleRequest();
    },[filterValues])
    return (
        <div className='w-full bg-white rounded-xl mt-6 px-10 pt-6 pb-10 shadow'>

            <div className="flex justify-center items-center pb-4 lg:pb-10">
                <h5 className="text-xl font-bold leading-none text-gray-900 pe-1">Region Expenses Chart (TOTAL)</h5>
            </div>
            <AlertMain error={error} text={"Error! month_expenses_map."} />
            {
                loading ?
                    <div className='flex flex-col items-center justify-center'>
                        <span className="loading loading-dots loading-lg mb-4 w-20 mt-2"></span>
                    </div> :
                    <>{data.length ? 
                        <Chart
                            chartType="GeoChart"
                            width="100%"
                            data={mapConvertData(data)}
                            options={options}
                        />:null
                    }
                    </>
            }
        </div>

    );
}
