import { handleImage } from "../../utils/utils";
export default function UserOrgInfo(props) {
    const { userInfo, isAdmin } = props;
    const profile_image = userInfo.profile_image ? handleImage(userInfo.profile_image.data) : "/images/Profile_Default.png"
    console.log(userInfo)
    return (
        <form onSubmit={e => { e.preventDefault(); }} className="ring-4 ring-blue-400 rounded-lg p-2">
            <figure className="mb-4">
                <img
                    src={profile_image}
                    alt="profile"
                    width="100%" />
            </figure>
            <div className="rounded-lg bg-blue-300 p-2 mb-4">
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>User Info</h1>
                <h1 className="text-black font-bold">👨‍💼 Complete Name:</h1>
                <p>{userInfo.complete_name}</p>
                <h1 className="text-black font-bold">👨‍💼 User Name:</h1>
                <p>{userInfo.user_name}</p>
                <h1 className="text-black font-bold">📆 Bridthday:</h1>
                <p>{userInfo.birthday_date ? userInfo.birthday_date.split('T')[0] : "None"}</p>
            </div>
            <div className="rounded-lg bg-violet-300 p-2 mb-4">
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Employee Info</h1>
                <h1 className="text-black font-bold">🗃️ Organization:</h1>
                <p>{userInfo.organization}</p>
                <h1 className="text-black font-bold">💼 Department:</h1>
                <p>{userInfo.department}</p>
                <h1 className="text-black font-bold">🧰 Position:</h1>
                <p>{userInfo.user_position}</p>
                <h1 className="text-black font-bold">🗺️ Area:</h1>
                <p>{userInfo.user_area}</p>
                <h1 className="text-black font-bold">📍 City:</h1>
                <p>{userInfo.user_city}</p>
                <h1 className="text-black font-bold">📆 Hired Date:</h1>
                <p>{userInfo.hired_date ? userInfo.hired_date.split('T')[0] : "None"}</p>
                <h1 className="text-black font-bold">📧 Email:</h1>
                <p>{userInfo.email}</p>
                <h1 className="text-black font-bold">📲 Phone Number:</h1>
                <p>{userInfo.phone_number ? userInfo.phone_number : "None"}</p>
                <h1 className="text-black font-bold">👨‍🏫 Direct Superior:</h1>
                <p>{userInfo.superior_name ? userInfo.superior_name : "None"}</p>
            </div>
            {isAdmin ?
                <div className="rounded-lg bg-red-100 p-2 mb-4">
                    <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Personal Info</h1>
                    <h1 className="text-black font-bold">🩸 Blood Type:</h1>
                    <p>{userInfo.blood_type}</p>
                    <h1 className="text-black font-bold">🩺 NSS:</h1>
                    <p>{userInfo.nss}</p>
                    <h1 className="text-black font-bold mb-2">🚑 Diseases:</h1>
                    {
                        userInfo.diseases ?
                            <div className="overflow-x-auto border-2 border-white mb-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(userInfo.diseases).map((key, ind) =>
                                                <tr key={ind}>
                                                    <td>
                                                        <p>{ind + 1}</p>
                                                    </td>
                                                    <td>
                                                        <p>{userInfo.diseases[key]}</p>
                                                    </td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div> : <p>None</p>
                    }
                    <h1 className="text-black font-bold mb-2">👨‍👩‍👧‍👦​ Insurance Beneficiaries:</h1>
                    {
                        userInfo.insurance_beneficiaries ?
                            <div className="overflow-x-auto border-2 border-white">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Name</th>
                                            <th>Relationship</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(userInfo.insurance_beneficiaries).map((key, ind) =>
                                                <tr key={ind}>
                                                    <td>
                                                        <p>{ind + 1}</p>
                                                    </td>
                                                    <td>
                                                        <p>{userInfo.insurance_beneficiaries[key].name}</p>
                                                    </td>
                                                    <td>
                                                        <p>{userInfo.insurance_beneficiaries[key].relationship}</p>
                                                    </td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div> : <p>None</p>
                    }
                </div> : null}
            {isAdmin ?
                <div className="rounded-lg bg-green-300 p-2 mb-4">
                    <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Applications</h1>
                    <h1 className="text-black font-bold mb-2">🔑​​ Permissions:</h1>
                    {
                        userInfo.roles ?
                            <div className="overflow-x-auto border-2 border-white">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>App</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(userInfo.roles.permissions).map((key, ind) =>
                                                <tr key={ind}>
                                                    <td>
                                                        <p>{ind + 1}</p>
                                                    </td>
                                                    <td>
                                                        <p>{key}</p>
                                                    </td>
                                                    <td>
                                                        <p>{userInfo.roles.permissions[key]}</p>
                                                    </td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div> : <p>None</p>
                    }
                </div> :
                null
            }

        </form>
    );
}