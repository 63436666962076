import GridTableHead from "./GridTableHead";

export default function GridTable(props) {
    const {title, addClickHandle, Cards, filterValues, dashboardRequest, cardsData, disableReport, diableAdd = false} = props;
    return (
        <div className='w-full mt-6'>
            <GridTableHead title={title} 
                addClickHandle={addClickHandle} 
                filterValues={filterValues}
                dashboardRequest={dashboardRequest}
                cardsData={cardsData}
                disableReport={disableReport}
                diableAdd={diableAdd}/>
                
            <Cards/>
        </div>
    );
}