import { useState } from "react";
import UserWallEvent from "../form/UserWallEvent"
import IdSelector from "../inputs/IdSelector";
import AddAndUpdateUser from "../form/AddAndUpdateUser";
export default function OrganizationButtons({ organization_id, dashboardRequest, setOrgId, orgId }) {
    const [showEvent, setShowEvent] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const selectorHandle = (e) => {
        setOrgId(e.target.value);
    }
    const userOrganizations = JSON.parse(sessionStorage.getItem("user_organizations"));
    return (
        <div className="flex bg-white rounded-md p-2 mb-4">
                        <IdSelector
                title="Oranization"
                data={userOrganizations}
                selectorHandle={selectorHandle}
                value={orgId}
                defaultVal={organization_id}/>
            <button 
                onClick={() => setShowUser(true)}
                className="btn btn-primary btn-outline mx-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                </svg>
                Add User
            </button>
            <button
                onClick={() => setShowEvent(true)}
                className="btn btn-secondary btn-outline mr-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                </svg>
                Add Event
            </button>

            <UserWallEvent
                organization_id={orgId}
                handleForm={setShowEvent}
                show={showEvent}
                dashboardRequest={dashboardRequest} />
            <AddAndUpdateUser
                organization_id={orgId}
                handleForm={setShowUser}
                show={showUser}
                dashboardRequest={dashboardRequest} />
        </div>
    )
}