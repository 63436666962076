
export default function BeneficiariesTable({ setBodyFetch, bodyFetch }) {
    const benList =["Wife", "Husband", "Concubine", "Daughter", "Son"];
    const deleteHandle = (key) => {
        const delVal = {
            ...bodyFetch.insurance_beneficiaries
        }
        delete delVal[key];
        setBodyFetch({
            ...bodyFetch,
            "insurance_beneficiaries": delVal,
        })
    }

    const addHandle = (key) => {
        const editVal = {
            ...bodyFetch.insurance_beneficiaries,
            [key]: {name:"", relationship:benList[0]}
        }
        setBodyFetch({
            ...bodyFetch,
            "insurance_beneficiaries": editVal,
        })
    }

    const editHandled = (key, val, prop) => {
        const outVal = {
            ...bodyFetch.insurance_beneficiaries[key],
            [prop]: val
        }
        const inVal = {
            ...bodyFetch.insurance_beneficiaries,
            [key]: outVal
        }
        setBodyFetch({
            ...bodyFetch,
            "insurance_beneficiaries": inVal,
        })
    }
    const getMaxKeyHandle = () => {
        const listKey = Object.keys(bodyFetch.insurance_beneficiaries).map((val) => parseInt(val));
        if (!listKey.length) {
            return "1";
        }
        else {
            const maxVal = Math.max(...listKey) + 1;
            return maxVal.toString();
        }
    }
    return (
        <div className="form-control mb-4">
            <label class="block mb-2 text-sm font-medium text-black">Insurance Beneficiaries
                <button
                    onClick={() => addHandle(getMaxKeyHandle())}
                    type="button"
                    className="bg-green-400 rounded-lg px-2 ml-4">
                    Add New
                </button>
            </label>
            {Object.keys(bodyFetch.insurance_beneficiaries).length ?
                (<div className="overflow-x-auto border-2 border-white">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>relationship</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(bodyFetch.insurance_beneficiaries).map((key, ind) =>
                                    <tr key={ind}>
                                        <td>
                                            <input
                                                onChange={(e) => editHandled(key, e.target.value, "name")}
                                                className="rounded-lg px-2"
                                                value={bodyFetch.insurance_beneficiaries[key].name} />
                                        </td>
                                        <td>
                                            <select
                                                onChange={(e)=>editHandled(key ,e.target.value, "relationship")}
                                                value={bodyFetch.insurance_beneficiaries[key].relationship}
                                                className="input input-bordered border-gray-400"
                                            >
                                                {
                                                    benList.map((value, index) =>
                                                        <option key={index} value={value} label={value} />)
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => deleteHandle(key)}
                                                className="bg-white rounded-lg px-2">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>) : null
            }

        </div>
    );
}