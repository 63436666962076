import AddAndUpdateUserBody from './AddAndUpdateUserBody';
import { useTypeKey } from '../../hooks/useTypeKey';
export default function AddAndUpdateUser(props) {
    const { organization_id, dashboardRequest, handleForm, show} = props;
    const typeKeyHandle=()=>{
        handleForm(false);
    }

    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            {
                show &&
                (<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                        <h3 className="font-bold text-lg mb-4">Add User</h3>
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <AddAndUpdateUserBody 
                                organization_id={organization_id}
                                dashboardRequest={dashboardRequest}
                                handleForm= {handleForm}
                                />
                        </form>
                    </div>
                </div>)
            }
        </>

    );
};

