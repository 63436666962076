
function Home() {
	return (
		<div className="w-full h-full flex justify-center">
			<div className="content-center">
			<div className="card bg-base-100 w-96 shadow-xl">
				<figure className="px-10 pt-10">
					<img
						src="/images/pacLogoBlack.svg"
						alt="Shoes"
						className="rounded-xl" />
				</figure>
				<div className="card-body items-center text-center">
					<h2 className="card-title">Welcome to PAC MEXICO</h2>
				</div>
			</div>
			</div>

		</div>
	);
}

export default Home;
