import { useEffect, useCallback } from "react";
export const useTypeKey = (typeKeyHandle, key) => {
    const escFunction = useCallback((event) => {
        if (event.key === key) {
            typeKeyHandle();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
};