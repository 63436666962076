import useFetch from "../../hooks/useFetch";
import useFetchSelector from "../../hooks/useFetchSelector";
export default function IdSelector(props) {
    const { title,value, selectorHandle, data, defaultVal } = props;
    return (
            <select
                className="input input-bordered border-gray-400"
                name={title.toLowerCase()}
                defaultValue={defaultVal}
                value={value}
                onChange={selectorHandle}
            >
                {
                    data.map((value, index) =>
                        <option key={index} value={value.id} label={value.name} />)
                }
            </select>
    );
}