import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import geoJson from "./world.json";

const TicketsPerState: React.FC = () => {
	const data = [
		{ name: "Jalisco", value: 2 },
		{ name: "Nuevo León", value: 555 },
		{ name: "Zacatecas", value: 66 },
		{ name: "Guerrero", value: 66 },
	];

	echarts.registerMap("Mexico", geoJson);

	const options = {
		visualMap: {
			left: "right",
			inRange: {
				color: [
					"#313695",
					"#4575b4",
					"#74add1",
					"#abd9e9",
					"#e0f3f8",
					"#ffffbf",
					"#fee090",
					"#fdae61",
					"#f46d43",
					"#d73027",
					"#a50026",
				],
			},
			text: ["High", "Low"],
			calculable: true,
		},
		tooltip: {
			trigger: "item",
			showDelay: 0,
			transitionDuration: 0.2,
		},
		toolbox: {
			feature: {
				dataView: { show: true, readOnly: false },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},

		series: [
			{
				id: "population",
				type: "map",
				roam: true,
				map: "Mexico",
				name: "Ticket in",

				animationDurationUpdate: 1000,
				universalTransition: true,
				data: data,
			},
		],
	};

	return <ReactECharts option={options} style={{ height: "500px" }} />;
};

export default TicketsPerState;
