export default function NumericInput(props) {
    const { title, value, keyName, selectorHandle  } = props;
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
        // Prevent the page/container scrolling
        e.stopPropagation()   

    }
      
    return (
        <div className="form-control">
            <label for="large-input" class="block mb-2 text-sm font-medium text-black font-bold">{title}</label>
            <input
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
                type="number"
                step="0.01"
                min="0.01"
                className="input input-bordered border-gray-400"
                onWheel={numberInputOnWheelPreventChange}
                name={title.toLowerCase()}
                required 
            />
        </div>
    );
}