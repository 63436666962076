import { useState} from 'react';

const useFetchClickFormFiles = (url, body,dashboardRequest, closeForm) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

	const handleRequest = async () => {
        try {
            
            const formData = new FormData();
            body.files.forEach((file, index) => {
                formData.append('files', file);
            });
            for ( var key in body ) {
                if(key!=='files')formData.append(key, body[key]);
            }
            setLoading(true);
            const response = await fetch(
                url,
                {
                    method: "POST",
                    body: formData,
                });
            if (!response.ok) {
                setError(`Error. Server NOT OK`);
            }
            else{
                setError(null);
                closeForm(false);
                dashboardRequest();
            }

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
	};

    return { loading, error, handleRequest};
};

export default useFetchClickFormFiles;
