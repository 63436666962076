import { useState, useEffect } from "react";
import OrganizationGroupHead from "./OrganizationGroupHead";
import OrganizationGroupSupport from "./OrganizationGroupSupport";
import OrganizationGroupShort from "./OrganizationGroupShort";
import { getUserByLevelHandle } from "../../utils/utils";
import OrganizationChartDep from "./OrganizationChartDep";

export default function OrganizationChart({ dataChart, orgId, loading, dashboardRequest, isAdmin, sessionUserId }) {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const userOrganizations = JSON.parse(sessionStorage.getItem("user_organizations"));
    
    const firstLevel = getUserByLevelHandle(dataChart, 1);
    const secondLevel = getUserByLevelHandle(dataChart, 2);
    return (
        <div className=" rounded-3xl bg-[url('https://www.creativefabrica.com/wp-content/uploads/2021/07/27/Geometric-black-abstract-background-Graphics-15165102-1-580x363.jpg')] p-4 ">
            <h1 className="text-white text-6xl text-center mt-4 mb-8">
                {userOrganizations.filter((organization)=>organization.id==orgId)[0].name}</h1>
            <OrganizationChartDep orgId={orgId} />
            {
                (!isMobile && !loading) && (
                    <div >
                        <OrganizationGroupHead
                            sessionUserId={sessionUserId}
                            isAdmin={isAdmin} 
                            usersInfo={firstLevel} 
                            dashboardRequest={dashboardRequest} />
                        <div className="flex ">
                            <OrganizationGroupSupport
                                sessionUserId={sessionUserId} 
                                isAdmin={isAdmin} 
                                usersInfo={secondLevel} 
                                dataChart={dataChart}
                                dashboardRequest={dashboardRequest} />
                        </div>
                    </div>
                )
            }

        </div>
    );
}