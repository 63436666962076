
import ExpiredDateSelector from "../inputs/ExpiredDateSelector";
import ComponentFileInput from "./ComponentFileInput";

export default function UserOrgDocUpload(props) {
    const { dateHandle, files, clearFilesHandle, handleRequestSubmit, expired, isAdmin } = props;
    return (

        <div>
            <ComponentFileInput
                file={files[0]}
                fistPage={true}
                fileName={files[0].name}
                hoverColor={false} />

                    <div className="flex mt-4">
                        <button
                            onClick={handleRequestSubmit}
                            className="btn btn-square btn-success mr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>

                        {
                            expired &&
                            (<ExpiredDateSelector dateHandle={dateHandle} />)
                        }
                        <div class="grow" />
                        <button
                            onClick={clearFilesHandle}
                            className="btn btn-square btn-warning mr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                    </div>
        </div>


    );
}