import React from 'react';
import ReactECharts from 'echarts-for-react';

const TicketsPerUser: React.FC = () => {
  const colors = ['#5470C6', '#91CC75', '#EE6666'];
const options = {
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  grid: {
    right: '20%'
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  legend: {
    data: ['Tickets', 'Average Response Time', 'Average Ticket Duration']
  },
  xAxis: [
    {
      type: 'category',
      axisLabel: { interval: 0, rotate: 25 },
      axisTick: {
        alignWithLabel: true,
      },
      // prettier-ignore
      data: ["Ludim Gonzalez", "Osvaldo Sepulveda", "Alfredo Plazola", "Victor Zarate", "Gabriel Arteaga", "Mario Chavez", "Diego Rodriguez", "Jaime Perez", "Everardo Jaime Garcia", "Pablo Ruiz"]
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Tickets',
      position: 'left',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[0]
        }
      },
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      name: 'Response',
      position: 'right',
      alignTicks: true,
      offset: 80,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[1]
        }
      },
      axisLabel: {
        formatter: '{value} hr'
      }
    },
    {
      type: 'value',
      name: 'Duration',
      position: 'right',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[2]
        }
      },
      axisLabel: {
        formatter: '{value} days'
      }
    }
  ],
  series: [
    {
      name: 'Tickets',
      type: 'bar',
      data: [712, 651, 644, 425, 412, 397, 392, 367, 357, 330]
    },
    {
      name: 'Average Response Time',
      type: 'line',
      yAxisIndex: 1,
      data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
    },
    {
      name: 'Average Ticket Duration',
      type: 'line',
      yAxisIndex: 2,
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    }
  ]
};

  return <ReactECharts option={options} />;
};

export default TicketsPerUser;