import React, { useState, useEffect, useRef } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import Pdf from './Pdf';
import Image from './Image';
import { handleImage } from '../../utils/utils';


export default function ComponentFileInput(props) {
    const { file, fistPage, fileName, hoverColor = true } = props;
    const [imageSrc, setImageSrc] = useState(null);

    const handleImageChange = (fileInput) => {
        if (fileInput) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target.result);
            };
            reader.readAsDataURL(fileInput);
        }
    };
    const styleHover = (hoverColor ?
        "w-full p-4 bg-white  rounded-lg shadow-lg hover:bg-info" :
        "w-full p-4 bg-white  rounded-lg shadow-lg")
    handleImageChange(file)
    return (
        <div
            className={styleHover}>

            {file &&
                (<div className="w-full bg-white"  >

                    <p className='text-center'>{fileName}</p>
                    {
                        fileName.toLowerCase().search("pdf") !== -1 ?
                            <Pdf fistPage={fistPage} file={file} /> :
                            <Image srcImage={imageSrc} fileName={fileName} />}
                </div>)
            }
        </div>
    );
}