import { useState, useEffect } from "react";

import useFetch from "../hooks/useFetch";

import ColumnChart from "../components/charts/ColumnChart";
import TypeRadialChart from "../components/charts/TypeRadialChart";
import ProgressRadialChart from "../components/charts/ProgressRadialChart";
import FilterHomeSearch from "../components/form/FilterHomeSearch";
import HeadStatsHome from "../components/ui/HeadStatsHome";
import BadgeFilter from "../components/ui/BadgeFilters";
import GeoExpenseChart from "../components/charts/GeoExpenseChart";
import AlertMain from "../components/ui/AlertMain";

import { getUserData, searchBody, progressValue, 
    progressSpentMoney, monthDateWithThreshold } from "../utils/utils";


export default function UserHome() {
    const [showSearch, setShowSearch] = useState(false);

    const dayThreshold = 25;
    const userInfo = getUserData();

    const { from, to } = monthDateWithThreshold(new Date(), dayThreshold)
    const [filterValues, setFilterValues] = useState(
        searchBody(userInfo.isAdmin ? 0 : userInfo.userId,
            userInfo.isAdmin ? "ALL" : userInfo.userName, from, to))

    const { data: expenseData,
        loading: loadingExpense,
        error: errorExpense,
        handleRequest: handleRequestExpense } = useFetch(
            `${process.env.REACT_APP_API_URL}expense_cards`,
            filterValues)

    const { data: budgetData,
        loading: loadingBudget,
        error: errorBudget,
        handleRequest: handleRequestBudget } = useFetch(
            `${process.env.REACT_APP_API_URL}budget_cards`,
            filterValues)
    useEffect(() => {
        handleRequestBudget();
        handleRequestExpense();
    }, [filterValues])
    return (
        <div className="p-4">
            <HeadStatsHome
                expenseData={expenseData}
                budgetData={budgetData} />
            <BadgeFilter
                values={filterValues}
                handleForm={setShowSearch}
                loading={loadingExpense || loadingBudget} />
            <AlertMain error={errorExpense} text={"Error! expense_cards."} />
            <AlertMain error={errorBudget} text={"Error! budget_cards."} />
            <div className="lg:grid lg:grid-cols-3 lg:gap-4">
                <ProgressRadialChart
                    value={progressValue(budgetData, "status", "APPROVED")}
                    chartTitle={"Budget Authorization"}
                    valueTitle={"Budget Progress"} />
                <ProgressRadialChart
                    value={progressSpentMoney(budgetData, expenseData, "status", "APPROVED")}
                    chartTitle={"Budget VS Expense"}
                    valueTitle={"Spent Money"} />
                <ProgressRadialChart
                    value={progressValue(expenseData, "status", "APPROVED")}
                    chartTitle={"Expense Authorization"}
                    valueTitle={"Expense Progress"} />
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-4">

                <TypeRadialChart
                    filterValues={filterValues}
                    url="month_budgets_type"
                    keyName="budget_type"
                    title="Budgets Types" />
                <TypeRadialChart
                    filterValues={filterValues}
                    url="month_expenses_type"
                    keyName="expense_type"
                    title="Expenses Types" />
            </div>
            <ColumnChart
                title={"Month Expenses by Day (TOTAL)"}
                filterValues={filterValues}
                url={"month_expenses_day"}
                valueName={"Money"} />
            {userInfo.isAdmin && (<GeoExpenseChart filterValues={filterValues} />)}
            {userInfo.isAdmin && (<ColumnChart
                title={"Expenses by User (TOTAL)"}
                filterValues={filterValues}
                url={"month_expenses_user"}
                valueName={"Money"} />)}
            <ColumnChart
                title={"Expenses by Client (TOTAL)"}
                filterValues={filterValues}
                url={"month_expenses_client"}
                valueName={"Money"} />
            <FilterHomeSearch
                show={showSearch}
                handleForm={setShowSearch}

                filterValues={filterValues}
                setFilterValues={setFilterValues}

                isAdmin={userInfo.isAdmin}
                dayThreshold={dayThreshold} />
        </div>
    );
}