import { useState } from "react";

export const buildDate = ( year, month) => {
    const dateBuilded = new Date(year, month, 1,0,0,0,0);
    return dateBuilded
}

export default function ExpiredDateSelector(props) {
    const { dateHandle } = props;
    const currentDatetime = new Date();
    const [ year, setYear] = useState(null);
    const [ month, setMonth] = useState(null);
    function generateYearList(startDate, numberOfYears) {
        let yearList = [];
        const startYear = startDate.getFullYear();

        for (let i = 0; i < numberOfYears; i++) {
            const year = startYear + i
            const yearFormat = year.toString().slice(2)
            yearList.push({year:year, yearFormat:yearFormat});
        }

        return yearList;
    }
    const yearHandle = (event) =>{
        setYear(event.target.value)
        if(month){
            dateHandle(buildDate(event.target.value, month))
        }
    }
    const monthHandle = (event) =>{
        setMonth(event.target.value)
        if(year){
            dateHandle(buildDate(year, event.target.value ))
        }
    }
    return (
        <div className="flex flex-row rounded-lg bg-white w-48">
            <select 
                value={month}
                onChange={monthHandle}
                className="select select-ghost  ml-2">
                <option disabled selected>MM</option>
                {
                    Array.from({ length: 12 }, (value, index) => 
                        <option key={index} value={index} label={index + 1} />)
                }
            </select>

            <h1 className="text-4xl">/</h1>
            <select 
                value={year}
                onChange={yearHandle}
                className="select select-ghost  mr-2">
                <option disabled selected>YY</option>
                {
                    generateYearList(currentDatetime, 12).map((data, index) => 
                        <option key={index} value={data.year} label={data.yearFormat} />)
                }
            </select>
        </div>
    );
}