import React, { useState, useEffect } from "react";

const ChatBar = ({ socket }) => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		socket.on("newUserResponse", data => setUsers(data));
	}, [socket, users]);

    /*
    <div>
                <h2>Chat</h2>
					{users.map(user => (
                        <div className="indicator pr-4">
                            <p key={user}>{user} <span class="indicator-item indicator-middle badge badge-accent"></span></p>
                        </div>
					))}
				
			</div>
    */

	return (
		<div className="flex items-center gap-4">
            <div>
					{users.map((user) => 
                        localStorage.getItem("userName") != user ?
                        <div className="flex items-center">
                            <div className="indicator pr-6">
                                <p key={user}><b>{user}</b><span class="indicator-item indicator-middle badge badge-accent"></span></p>
                            </div>
                            <p className="pl-6 text-xs">ONLINE</p>
                            </div>
                        : null
					)}
				
			</div>
			
		</div>
	);
};

export default ChatBar;
