import React, { useState, useEffect } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useFetchPost from "../../hooks/useFetchPost";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { toast } from "react-toastify";

function ScheduleUser(props) {
	const { changeDate, userIdSelected, userId, refresh } = props;
	var [refetch, setRefetch] = useState(false);
	console.log(props);
	const [eventsArray, setEventsArray] = useState([]);
	const [eventData, setEventData] = useState({
		title: "",
		description: "",
		start: "",
		end: "",
		allDay: true,
		backgroundColor: "#f1f1f1",
		borderColor: "#f1f1f1",
		classNames: ["h-24"],
	});

	const endpoint = process.env.REACT_APP_API_URL + "events/mulitipleUsers";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		console.log("ready");
		setLoading(true);
		console.log(userIdSelected);
		axios
			.post(
				endpoint,
				{ userId: userIdSelected },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then(response => {
				setData(response.data.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [JSON.stringify(userIdSelected), refresh, refetch]);
	console.log(data);
	/*const {
		data: events,
		loading,
		error,
		refetch,
	} = useFetchPost(endpoint, { userId: props.userIdSelected });
	console.log(events);*/
	//setEventsArray(events);

	const formatEvents = events => {
		if (events !== null) {
			return events.map(event => {
				const {
					id,
					userId,
					title,
					description,
					start,
					end,
					backgroundColor,
					borderColor,
					user,
				} = event;

				let startTime = new Date(start);
				let endTime = new Date(end);

				return {
					extendedProps: {
						id: id,
						userId: userId,
						name: user.name,
					},
					title: title,
					description: description == "NULL" ? "" : description,
					start: startTime.setDate(startTime.getDate() + 1),
					end: endTime.setDate(endTime.getDate() + 2),
					allDay: true,
					backgroundColor: "#" + backgroundColor,
					borderColor: "#" + borderColor,

					classNames: [
						"h-18",
						"flex",
						"content-center",
						"items-center",
						"px-1",
						"m-1",
					],
					//backgroundColor: "#f1f1f1",
				};
			});
		}
	};

	const renderEventContent = eventInfo => {
		const event_userId = eventInfo.event.extendedProps.userId;
		console.log(event_userId, userId);
		const start = eventInfo.event.startStr;
		const end = eventInfo.event.endStr;
		console.log(start, end);
		let startTime = new Date(start);
		let endTime = new Date(end);
		const todayDate = new Date();
		startTime = startTime.setDate(startTime.getDate() + 1);
		if (end === "") {
			endTime = new Date(start);
		}

		if (event_userId == userId && startTime > todayDate) {
			return (
				<div className="w-full flex items-center">
					<FontAwesomeIcon
						icon={faTrashCan}
						className="cursor-pointer mr-2"
						onClick={e => {
							e.stopPropagation();
							handleDeleteClick(eventInfo.event.extendedProps.id);
						}}
					/>
					<div className="w-full flex flex-wrap">
						<div className="w-full whitespace-normal text-sm">
							{eventInfo.event.title}
						</div>
						<div className="w-full whitespace-normal text-xs">
							<i>{eventInfo.event.extendedProps.description}</i>
						</div>
						<div className="w-full whitespace-normal text-xs">
							<i> - {eventInfo.event.extendedProps.name}</i>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="w-full flex items-center">
					<div className="w-full flex flex-wrap">
						<div className="w-full whitespace-normal text-sm">
							{eventInfo.event.title}
						</div>
						<div className="w-full whitespace-normal text-xs">
							<i>{eventInfo.event.extendedProps.description}</i>
						</div>
						<div className="w-full whitespace-normal text-xs">
							<i> - {eventInfo.event.extendedProps.name}</i>
						</div>
					</div>
				</div>
			);
		}
	};

	const handleDeleteClick = async eventId => {
		const endpoint = process.env.REACT_APP_API_URL + "events/" + eventId;
		try {
			const response = await axios.delete(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			console.log(response.data);
			console.log(true);
			toast.success(response.data.message);
			setRefetch(!refetch);
		} catch (error) {
			console.log(error);
			toast.error(error);
		}
	};

	const handlePreset = event => {
		console.log(event.target.value);
	};
	const handleEventClick = event => {
		console.log(event);
		console.log(event.event.startStr, event.event.endStr);
	};

	const handleEventChange = async event => {
		const id = event.event.extendedProps.id;
		const event_userId = event.event.extendedProps.userId;
		const start = event.event.startStr;
		const end = event.event.endStr;
		console.log(id, start, end);
		let startTime = new Date(start);
		let endTime = new Date(end);
		const todayDate = new Date();
		startTime = startTime.setDate(startTime.getDate() + 1);
		if (end === "") {
			endTime = new Date(start);
		}

		console.log(format(startTime, "yyyy-MM-dd"));
		console.log(format(endTime, "yyyy-MM-dd"));

		if (event_userId == userId && startTime > todayDate) {
			const endpoint =
				process.env.REACT_APP_API_URL + "events/updateDate";
			try {
				const response = await axios.put(
					endpoint,
					{
						id: id,
						start: format(startTime, "yyyy-MM-dd"),
						end: format(endTime, "yyyy-MM-dd"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				toast.success(response.data.message);
				console.log(true);
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			if (event_userId != userId) {
				toast.warning("Can't modify events from other users");
			} else {
				toast.warning("Can't modify old events");
			}
			setRefetch(!refetch);
		}
	};
	const handleDateClick = arg => {
		console.log(arg);

		changeDate(arg);

		/*setState({
				// add new event data
				calendarEvents: this.state.calendarEvents.concat({
					// creates a new array
					title: "New Event",
					start: arg.date,
					allDay: arg.allDay,
				}),
			});*/
	};

	return (
		<div className="w-full basis-5/6 demo-app hidden sm:block">
			<div className="w-full demo-app-calendar">
				<FullCalendar
					contentHeight="auto"
					className="w-full h-full"
					themeSystem="bootstrap"
					editable={true}
					defaultView="dayGridWeek"
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "dayGridMonth,dayGridWeek,dayGridDay",
					}}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					dateClick={handleDateClick}
					eventClick={handleEventClick}
					eventChange={handleEventChange}
					//ref={this.calendarComponentRef}
					events={formatEvents(data)}
					eventContent={renderEventContent}
				/>
			</div>
		</div>
	);
}

export default ScheduleUser;
