import axios from "axios";

const sendEmail = async bodyData => {
		if (bodyData!= null) {
			const endpoint = process.env.REACT_APP_EMAIL_API_URL + "sendMail";
			try {
				const response = await axios.post(
					endpoint,
					bodyData,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
			} catch (error) {
				console.log(error);
			}
		} else {
			console.log("error");
		}
}

export default sendEmail;
