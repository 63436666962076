import OrganizationCard from "./OrganizationCard";
import { getUserByLevelAndSuperiorHandle } from "../../utils/utils";

export default function OrganizationGroupShort(props) {
    const { dashboardRequest, dataChart, levelRegional, index, left = true, right = true, isAdmin, sessionUserId } = props;
    const levelSingle = getUserByLevelAndSuperiorHandle(dataChart, index, levelRegional["id"]);
    return (
        <div className="grow-0">
            <div className="flex flex-row h-8">
                <div className={`basis-1/2 border-r-4 border-gray-400 border-dashed ${left ? "border-t-4" : ""}`} />
                <div className={`basis-1/2 border-gray-400 border-dashed ${right ? "border-t-4" : ""}`} />
            </div>
            <div className="flex flex-col">
                <div className="rounded-lg m-1 bg-white shadow-xl m-1 h-full ">
                    <OrganizationCard
                        sessionUserId={sessionUserId}
                        isAdmin={isAdmin}
                        userInfo={levelRegional}
                        dashboardRequest={dashboardRequest} />
                </div>
                {levelSingle.length && (
                    <div className="flex flex-row h-8">
                        <div className="basis-1/2 border-r-4 border-gray-400 border-dashed " />
                        <div className="basis-1/2" />
                    </div>)
                }
                <div className="flex-none rounded-lg m-1 bg-white shadow-xl m-1 h-full ">
                    {
                        levelSingle.map((user, index) =>
                            <OrganizationCard
                                sessionUserId={sessionUserId}
                                key={index}
                                isAdmin={isAdmin}
                                userInfo={user}
                                dashboardRequest={dashboardRequest} />)
                    }
                </div>
            </div>

        </div>
    );
}