import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useFetchPost from "../../hooks/useFetchPost";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { toast } from "react-toastify";

function Chats(props) {
	const {
		ticketId,
		changeTicket,
		userIdSelected,
		refresh,
		socket,
		ticketData,
		messages,
		ref,
	} = props;
	console.log(ticketData);
	var [refetch, setRefetch] = useState(false);
	const userName = localStorage.getItem("userName");
	const endpoint = process.env.REACT_APP_API_URL + "chats/" + ticketId;
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [test, setTest] = useState(null);

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	/*useEffect(() => {
		console.log("ready");
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log(data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
				scrollToBottom()
			});

			scrollToBottom()

	}, [refresh, refetch, ticketData]);*/

	if (loading)
		return <span className="loading loading-spinner loading-lg"></span>;

	return (
		<div className="h-full">
			{messages !== null ? (
				messages.map((message, i) => (
					<div
						className={`chat ${userName === message.userName ? "chat-end" : "chat-start"}`}
					>
						<div class="chat-header mb-1">
							{message.userName}
							<time class="text-xs opacity-50 ml-2">
								{new Date(message.date).toLocaleString()}
							</time>
						</div>
						<div class="chat-bubble">{message.message}</div>
					</div>
				))
			) : (
				<div>no data</div>
			)}
			<div ref={messagesEndRef} />
		</div>
	);
}

export default Chats;
