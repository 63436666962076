export default function FileInput(props) {
    const { files, handleFilesChange, title="Load Files", allowMultiple=true } = props;

    return (
        <div className="form-control w-full">
            <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex ">
                <svg className="fill-current w-4 h-4 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                <span >{title}</span>
                <input type="file" className="hidden" multiple={allowMultiple} onChange={handleFilesChange} />
            </label>
            <div className="w-full pt-4 pb-2">
                {
                    files.map((filesObj, index) => <span key={index} class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{filesObj.name}</span>)
                }
            </div>
        </div>
    );
};
