import { useState } from "react";

const styleAlert = {
    "error": {
        style: "flex flex-col rounded-lg w-full alert alert-error shadow-lg",
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    },
    "warnign": {
        style: "flex flex-col rounded-lg w-full alert alert-warning shadow-lg",
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
    },
    "info": {
        style: "flex flex-col rounded-lg w-full alert alert-info shadow-lg",
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="h-6 w-6 shrink-0 stroke-current">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
    },
    "success": {
        style: "flex flex-col rounded-lg w-full alert alert-success shadow-lg",
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    },
    "basic": {
        style: "flex flex-col rounded-lg w-full alert shadow-lg ring-4 ring-info",
        icon: <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info h-6 w-6 shrink-0">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
    }
}

export default function AlertComplete(props) {
    const { title, msg, style, type = "basic", buttonStyle="bg-white rounded-md px-2 hover:ring-4" } = props;
    const [showContent, setShowContent] = useState(false);

    const clickHandle = () => {
        setShowContent(!showContent);
    }
    return (
        <div className={style} >
            {
                msg &&
                (
                    <div role="alert" className={styleAlert[type].style}>

                        <div className="flex w-full">
                            {styleAlert[type].icon}
                            {title}
                            <div className="grow" />
                            <button onClick={clickHandle} className={buttonStyle}>

                                <p>{showContent ? "Close" : "Open"}</p></button>
                        </div>


                        {showContent && (msg)}
                    </div>
                )
            }
        </div >

    )
}