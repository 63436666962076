import React from 'react';
import ReactECharts from 'echarts-for-react';

const TicketsPerPriority: React.FC = () => {
  const options = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '0%',
    left: 'center'
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  series: [
    {
      name: 'Priority',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Low', itemStyle: {color: '#91cc75'} },
        { value: 735, name: 'Medium', itemStyle: {color: '#fac858'} },
        { value: 580, name: 'High', itemStyle: {color: '#c23531'} },
      ]
    }
  ]
};

  return <ReactECharts option={options} />;
};

export default TicketsPerPriority;