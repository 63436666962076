import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { dateFormatDB } from '../../utils/constants';

import DatePicker from '../inputs/DatePicker';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import TextInput from '../inputs/TextInput';
import AlertMain from '../ui/AlertMain';
import LoadingBar from '../ui/LoadingBar';


export default function UserWallEvent(props) {
    const { organization_id, dashboardRequest, handleForm, show } = props;
    
    const [misingsProps, setMisingsProps] = useState("")
    const [bodyFetch, setBodyFetch] = useState({
        "organization_id":organization_id,
        "event_name": "",
        "datetime_obj": new Date(),
        "datetime": format(new Date(), dateFormatDB),
    });
    const dateHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "datetime_obj": dt,
            "datetime": format(dt, dateFormatDB),
        })
    }
    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }
    useEffect(()=>{
        selectorHandle("organization_id", organization_id);
    },[organization_id])

    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}add_user_wall_event`,
        bodyFetch,
        dashboardRequest,
        handleForm
    )

    const verifyBody = () => {
        if (!bodyFetch.event_name) setMisingsProps("Warning: Write Event Name Field!")
        else {
            setMisingsProps("")
            handleRequest()
        }
    }
    
    return (
        <>
            {
                show &&
                (<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                        <h3 className="font-bold text-lg mb-4">Add Event</h3>
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="mb-4">
                                <TextInput
                                    title="Event Name"
                                    keyName="event_name"
                                    selectorHandle={selectorHandle}
                                    value={bodyFetch.event_name} />
                            </div>

                            <div className="mb-4">
                                <DatePicker
                                    title="Date"
                                    setSelectDate={dateHandle}
                                    selectedDate={bodyFetch.datetime_obj} />
                            </div >
                            {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 shrink-0 stroke-current"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                    <span>{misingsProps}</span>
                                </div>)}
                            <AlertMain error={error} text="Fail Add new Event"/>
                            {
                                    loading &&
                                    (<progress className="progress w-full progress-info mb-4"></progress>)
                                }
                            <button
                                onClick={verifyBody}
                                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>)
            }
        </>

    );
};

