import React, { useState, useEffect, useRef } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import Pdf from './Pdf';
import Image from './Image';
import { handleImage } from '../../utils/utils';


export default function ComponentFileDb(props) {
    const { file, fistPage, fileName, hoverColor = true, printRef = null } = props;
    const styleHover = (hoverColor ?
        "w-full p-4 bg-white  rounded-lg shadow-lg hover:bg-info" :
        "w-full p-4 bg-white  rounded-lg shadow-lg")
    return (
        <div
            className={styleHover}>

            {file &&
                (<div className="w-full bg-white"  >

                    <p className='text-center'>{fileName}</p>
                    {
                        fileName.toLowerCase().search("pdf") !== -1 ?
                            <Pdf fistPage={fistPage}
                                file={file}
                                printRef={printRef} /> :
                            <Image srcImage={handleImage(file.data)} 
                                fileName={fileName}
                                printRef={printRef} />}
                </div>)
            }
        </div>
    );
}