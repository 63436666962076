
export default function RoleTable({ data, setBodyFetch, bodyFetch }) {
    const checkPermissions = (key, val) => {
        if (!val) {
            const delVal = {
                ...bodyFetch.permissions
            }
            delete delVal[key];
            setBodyFetch({
                ...bodyFetch,
                "permissions": delVal,
            })
        }
        else {
            const addVal = {
                ...bodyFetch.permissions,
                [key]: "user"
            }
            setBodyFetch({
                ...bodyFetch,
                "permissions": addVal,
            })
        }
    }
    const setRole = (key, val) => {
        const editVal = {
            ...bodyFetch.permissions,
            [key]: val
        }
        setBodyFetch({
            ...bodyFetch,
            "permissions": editVal,
        })
    }
    return (
        <div className="overflow-x-auto border-2 border-white">
            <table className="table">
                <thead>
                    <tr>
                        <th>App</th>
                        <th>Enable</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, ind) =>
                            <tr key={ind}>
                                <td>{row.name}</td>
                                <td>
                                    <input
                                        onChange={(e) => checkPermissions(row.name, e.target.checked)}
                                        checked={row.name in bodyFetch.permissions}
                                        type="checkbox"
                                        className="checkbox checkbox-primary" />
                                </td>
                                <td>
                                    <select
                                        onChange={(e)=>setRole(row.name, e.target.value)}
                                        value={bodyFetch.permissions[row.name]}
                                        className="input input-bordered border-gray-400"
                                    >
                                        {
                                            row.permissions.map((value, index) =>
                                                <option key={index} value={value} label={value} />)
                                        }
                                    </select>
                                </td>
                            </tr>)
                    }
                </tbody>
            </table>
        </div>
    );
}