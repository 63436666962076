import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { dateFormatDB } from '../../utils/constants';
import { generatePass } from '../../utils/utils';
import useFetch from '../../hooks/useFetch';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import BasicSelector from '../inputs/BasicSelector';
import DateSelector from '../inputs/DateSelector';
import RoleTable from './RoleTable';
import DiseasesTable from './DiseasesTable';
import BeneficiariesTable from './BeneficiariesTable';

export default function AddAndUpdateUserBody(props) {
    const { organization_id, dashboardRequest, isNew = true, handleForm, body = {
        organization_id: organization_id,
        birthday_obj: null,
        birthday: null,
        hired_obj: null,
        hired: null,
        complete_name: "",
        user_name: "",
        account_active: true,
        location: "",
        position: "",
        area: "",
        email: "",
        pwd: "",
        superior_id: 0,
        phone_number: "",
        user_type: "user",
        blood_type: "",
        nss: "",
        diseases: {},
        insurance_beneficiaries: {},
        permissions: {}
    } } = props;

    const [misingsProps, setMisingsProps] = useState("")
    const [bodyFetch, setBodyFetch] = useState(body);
    const dateBirthdayHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "birthday_obj": dt,
            "birthday": format(dt, dateFormatDB),
        })
    }
    const dateHiredHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "hired_obj": dt,
            "hired": format(dt, dateFormatDB),
        })
    }

    const positionRequest = useFetch(`${process.env.REACT_APP_API_URL}user_position_selector_by_organization`, {
        organization_id: organization_id
    })
    const locationRequest = useFetch(`${process.env.REACT_APP_API_URL}user_location_selector_by_organization`, {
        organization_id: organization_id
    })
    const areaRequest = useFetch(`${process.env.REACT_APP_API_URL}user_area_selector_by_organization`, {
        organization_id: organization_id
    })
    const usersSupRequest = useFetch(`${process.env.REACT_APP_API_URL}user_organization_selector_boss`, {
        organization_id: organization_id
    })
    const rolesRequest = useFetch(`${process.env.REACT_APP_API_URL}user_roles`, {
        organization_id: organization_id
    })
    const userSubmit = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}${isNew ? "users2" : "update_register_user"}`,
        bodyFetch,
        dashboardRequest,
        handleForm
    )
    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }

    const verifyBody = () => {
        if (!bodyFetch.birthday) setMisingsProps("Warning: Select Birthday Date!")
        else if (!bodyFetch.hired) setMisingsProps("Warning: Select Hired Date!")
        else if (!bodyFetch.user_name) setMisingsProps("Warning: Write User Name!")
        else if (!bodyFetch.complete_name) setMisingsProps("Warning: Write Complete Name!")
        else if (!bodyFetch.location) setMisingsProps("Warning: Select location!")
        else if (!bodyFetch.position) setMisingsProps("Warning: Select position!")
        else if (!bodyFetch.area) setMisingsProps("Warning: Select area!")
        else if (!bodyFetch.email) setMisingsProps("Warning: Write email!")
        else if (!bodyFetch.phone_number) setMisingsProps("Warning: Write phone_number!")
        else if (!bodyFetch.pwd && isNew) setMisingsProps("Warning: Write a Password!")
        else {
            setMisingsProps("");
            userSubmit.handleRequest();
        }
    }
    useEffect(() => {
        locationRequest.handleRequest();
        positionRequest.handleRequest();
        areaRequest.handleRequest();
        rolesRequest.handleRequest();
        usersSupRequest.handleRequest();
    }, [organization_id]);
    return (
        <>
            <div className='rounded rounded-lg bg-blue-300 p-4 mb-4'>
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>User Info</h1>
                <div className="form-control mb-4">
                    <label className="label cursor-pointer">
                        <label class="block mb-2 text-sm font-medium text-black">Account Active</label>
                        <input
                            onChange={() => selectorHandle("account_active", !bodyFetch.account_active)}
                            checked={bodyFetch.account_active}
                            type="checkbox"
                            className="checkbox checkbox-primary" />
                    </label>
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">User Name</label>
                    <label className="input input-bordered flex items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
                        </svg>
                        <input
                            onChange={(e) => selectorHandle("user_name", e.target.value)}
                            value={bodyFetch.user_name}
                            type="text" className="grow" placeholder="User Name" />
                    </label>
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">Complete Name</label>
                    <label className="input input-bordered flex items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
                        </svg>
                        <input
                            onChange={(e) => selectorHandle("complete_name", e.target.value)}
                            value={bodyFetch.complete_name}
                            type="text" className="grow" placeholder="Complete Name" />
                    </label>
                </div>
                <div className="form-control mb-4">
                    <label className="label cursor-pointer">
                        <label class="block mb-2 text-sm font-medium text-black">Password: {bodyFetch.pwd}</label>
                        <button
                            type='button'
                            onClick={() => selectorHandle("pwd", generatePass())}
                            className="btn btn-xs btn-info">Generate</button>
                    </label>
                    <label
                        className="input input-bordered flex items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                fillRule="evenodd"
                                d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                                clipRule="evenodd" />
                        </svg>
                        <input
                            onChange={(e) => selectorHandle("pwd", e.target.value)}
                            value={bodyFetch.pwd}
                            type="password" className="grow" />
                    </label>
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">Birthday</label>
                    <DateSelector dateHandle={dateBirthdayHandle} dateSelect={bodyFetch.birthday_obj} />
                </div>
            </div>
            <div className='rounded rounded-lg bg-violet-300 p-4 mb-4'>
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Employee Info</h1>
                <div className="form-control mb-4">
                    <label for="large-input" class="block mb-2 text-sm font-medium text-black">Superior</label>
                    <select
                        className="input input-bordered border-gray-400"
                        value={bodyFetch.superior_id}
                        onChange={(e) => selectorHandle("superior_id", e.target.value)}
                        required
                    >
                        <option selected value={0}>None</option>
                        {
                            usersSupRequest.data?.map((value, index) =>
                                <option key={index} value={value.id} label={value.name} />)
                        }
                    </select>
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">Email</label>
                    <label className="input input-bordered flex items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                            <path
                                d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                        </svg>
                        <input
                            onChange={(e) => selectorHandle("email", e.target.value)}
                            value={bodyFetch.email}
                            type="text"
                            className="grow" placeholder="example@pacmexico.com.mx" />
                    </label>
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">Phone</label>
                    <label className="input input-bordered flex items-center gap-2">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd" />
                        </svg>

                        <input
                            onChange={(e) => selectorHandle("phone_number", e.target.value)}
                            value={bodyFetch.phone_number}
                            type="text"
                            className="grow" placeholder="Example: 3325062506" />
                    </label>
                </div>
                <div className="mb-4">
                    <BasicSelector
                        title="Position"
                        data={positionRequest.data}
                        value={bodyFetch.position}
                        keyName="position"
                        selectorHandle={selectorHandle}
                        allowAll={false} />
                </div>
                <div className="mb-4">
                    <BasicSelector
                        title="Area"
                        data={areaRequest.data}
                        value={bodyFetch.area}
                        keyName="area"
                        selectorHandle={selectorHandle}
                        allowAll={false} />
                </div>
                <div className="mb-4">
                    <BasicSelector
                        title="Location"
                        data={locationRequest.data}
                        value={bodyFetch.location}
                        keyName="location"
                        selectorHandle={selectorHandle}
                        allowAll={false} />
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">Hired</label>
                    <DateSelector dateHandle={dateHiredHandle} dateSelect={bodyFetch.hired_obj} />
                </div>

            </div>
            <div className='rounded rounded-lg bg-red-300 p-4 mb-4'>
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Personal Info</h1>
                <div className="mb-4">
                    <BasicSelector
                        title="Blood Type"
                        data={[{ name: "A+" }, { name: "O+" }, { name: "B+" },
                        { name: "AB+" }, { name: "A-" }, { name: "O-" },
                        { name: "B-" }, { name: "AB-" }]}
                        value={bodyFetch.blood_type}
                        keyName="blood_type"
                        selectorHandle={selectorHandle}
                        allowAll={false} />
                </div>
                <div className="form-control mb-4">
                    <label class="block mb-2 text-sm font-medium text-black">NSS</label>
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            onChange={(e) => selectorHandle("nss", e.target.value)}
                            value={bodyFetch.nss}
                            type="text" className="grow" placeholder="Numero de Seguridad Social" />
                    </label>
                </div>
                <DiseasesTable
                    setBodyFetch={setBodyFetch}
                    bodyFetch={bodyFetch} />
                <BeneficiariesTable
                    setBodyFetch={setBodyFetch}
                    bodyFetch={bodyFetch} />
            </div>
            <div className='rounded rounded-lg bg-green-300 p-4 mb-4'>
                <h1 className='text-xl font-bold text-center border-b-2 border-black mb-4'>Applications</h1>
                <div className="mb-4">
                    <BasicSelector
                        title="User Type"
                        data={[{ name: "user" }, { name: "admin" }, { name: "customer" }]}
                        value={bodyFetch.user_type}
                        keyName="user_type"
                        selectorHandle={selectorHandle}
                        allowAll={false} />
                </div>
                <div className="form-control">
                    <label class="block mb-2 text-sm font-medium text-black">Roles</label>
                    <RoleTable
                        data={rolesRequest.data}
                        setBodyFetch={setBodyFetch}
                        bodyFetch={bodyFetch} />
                </div>
            </div>
            {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <span>{misingsProps}</span>
            </div>)}
            {userSubmit.error && (<div role="alert" className="flex rounded-lg w-full alert alert-error">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Write Fail</span>
            </div>)}
            {
                (userSubmit.loading) &&
                (<progress className="progress w-full progress-info mb-4"></progress>)
            }
            <button
                type='button'
                onClick={verifyBody}
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
            >
                Submit
            </button>
        </>

    );
};

