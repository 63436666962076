import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

const listColors = {
    "blue": "badge bg-blue-400 gap-2 mr-4 text-white font-bold",
    "green": "badge bg-green-600 gap-2 mr-4 text-white font-bold",
    "violet": "badge bg-violet-600 gap-2 mr-4 text-white font-bold",
    "orange": "badge bg-orange-600 gap-2 mr-4 text-white font-bold",
    "fuchsia": "badge bg-fuchsia-600 gap-2 mr-4 text-white font-bold",
    "pink": "badge bg-pink-600 gap-2 mr-4 text-white font-bold",
    "slate": "badge bg-slate-600 gap-2 mr-4 text-white font-bold",
    "red": "badge bg-red-600 gap-2 mr-4 text-white font-bold"
}

export default function OrganizationChartDep({ orgId }) {
    const requestDep = useFetch(`${process.env.REACT_APP_API_URL}organization_departments`,
        {
            "organization_id": orgId
        }
    )
    useEffect(() => {
        requestDep.handleRequest();
    }, [orgId])
    return (
        <div className="text-center">
            {
                requestDep.data.map((dep, index) =>
                    <div key={index} className={listColors[dep["color"]]}>
                        {dep["name"]}
                    </div>)
            }
        </div>
    );
}