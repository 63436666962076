import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

import Ticket from "../../components/ui/Ticket";
import TicketsTable from "../../components/ui/TicketsTable";

function TicketsAdminView({ socket }) {
	console.log(socket);
	const tickets = localStorage.getItem("tickets");
	console.log(tickets);
	const [active, setActive] = useState(0);
	const [fseinfoActive, setFseInfoActive] = useState(null);
	const [openTickets, setOpenTickets] = useState([]);
	const [refresh, setRefresh] = useState(false);

	console.log(active);
	console.log(fseinfoActive);
	console.log(openTickets);

	const handleClick = event => {
		setActive(parseInt(event.target.id));
		setFseInfoActive(event.target.fseInfo);
		setRefresh(!refresh);
	};

	const changeTicket = index => {
		if (!openTickets.includes(index)) {
			setOpenTickets(current => [...openTickets, index]);
		}
	};

	const removeTicket = event => {
		event.stopPropagation();
		const ticketId = parseInt(event.target.id);
		console.log(ticketId);
		var newTickets = openTickets.filter(ticket => ticket !== ticketId);
		console.log(newTickets);

		setActive(0);

		setOpenTickets(newTickets);

		const room = "ticket-" + ticketId;
		const userId = localStorage.getItem("userId");
		const userName = localStorage.getItem("userName");
		const userEmail = localStorage.getItem("userEmail");
		const date = new Date().toISOString();

		socket.emit("sendBackUpChat", {
			userId,
			userName,
			userEmail,
			room,
			ticketId,
			date,
		});

		//socket.emit("disconnect");
	};

	return (
		<>
			<div class="tabs">
				<a
					id={0}
					className={`cursor-pointer tab tab-lg tab-lifted ${
						active === 0 ? "tab-active" : ""
					}`}
					onClick={handleClick}
				>
					Tickets Table
				</a>
				{openTickets.map((ticket, i) => (
					<a
						id={ticket}
						className={`cursor-pointer tab tab-lg tab-lifted ${
							active === ticket ? "tab-active" : ""
						}`}
						onClick={handleClick}
					>
						Ticket #{ticket}
						<button
							class="btn btn-sm btn-circle btn-ghost ml-2"
							id={ticket}
							onClick={removeTicket}
						>
							✕
						</button>
					</a>
				))}
			</div>

			<div>
				{active !== 0 ? (
					<Ticket ticketId={active} tickets={socket} socket={socket} />
				) : (
					<TicketsTable changeTicket={changeTicket} tickets={tickets} />
				)}
			</div>
		</>
	);
}

export default TicketsAdminView;
