import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { dateFormatDB } from '../../utils/constants';

import NumericInput from '../inputs/NumericInput';
import TextArea from '../inputs/TextArea';
import DatePicker from '../inputs/DatePicker';
import BasicSelector from '../inputs/BasicSelector';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import { useTypeKey } from '../../hooks/useTypeKey';

export default function AddAndUpdateBudget(props) {
    const { show, handleForm, dashboardRequest, 
        startBody, url, title, isAdmin=false, isRejected=false } = props;
    const [misingsProps, setMisingsProps] = useState("")
    const [bodyFetch, setBodyFetch] = useState(startBody);
    const dateHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "datetime_obj": dt,
            "datetime": format(dt, dateFormatDB),
        })
    }
    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }
    
    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}${url}`,
        bodyFetch,
        dashboardRequest,
        handleForm
    )
    function getDecimalNumber(numericValue) {
        const numToString = `${numericValue}`
        if (numToString.indexOf('.') < 0) return 0
        const numDec = numToString.split(".")[1]
        return numDec.length
    }
    const verifyBody = () => {
        if (!bodyFetch.principal) setMisingsProps("Warning: Select a Principal!")
        else if (!bodyFetch.client_name) setMisingsProps("Warning: Select a Client!")
        else if (!bodyFetch.budget_type) setMisingsProps("Warning: Select a Type!")
        else if (!bodyFetch.amount) setMisingsProps("Warning: Write a Amount!")
        else if (getDecimalNumber(bodyFetch.amount) > 2) setMisingsProps("Warning: Only 2 decimals are allowed in the Amount!")
        else if (!bodyFetch.reason) setMisingsProps("Warning: Write a Reason!")
        else if (isRejected && !bodyFetch.denial_msg)setMisingsProps("Warning: Write a Denial Message!")
        else {
            setMisingsProps("")
            handleRequest()
        }
    }

    const principalsData = JSON.parse(sessionStorage.getItem("principals"));
    const clientsData = JSON.parse(sessionStorage.getItem("clients"));
    const typesData = JSON.parse(sessionStorage.getItem("budget_types"));
    const typeKeyHandle=()=>{
        handleForm(false);
    }
    useEffect(() => {
        setBodyFetch(startBody)
        setMisingsProps("");
    }, [show])
    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            {
                show && 
                    (<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                        <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                            <h3 className="font-bold text-lg mb-4">{title}</h3>
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                            <form onSubmit={e => { e.preventDefault(); }}>
                                <div className="mb-4">
                                    <BasicSelector
                                        title="Principal"
                                        keyName="principal"
                                        data={principalsData}
                                        selectorHandle={selectorHandle}
                                        value={bodyFetch.principal}
                                        allowAll={false} />
                                </div>
                                <div className="mb-4">
                                    <BasicSelector
                                        title="Client"
                                        keyName="client_name"
                                        data={clientsData}
                                        selectorHandle={selectorHandle}
                                        value={bodyFetch.client_name}
                                        allowAll={false} />
                                </div>
                                <div className="mb-4">
                                    <BasicSelector
                                        title="Type"
                                        keyName="budget_type"
                                        data={typesData}
                                        selectorHandle={selectorHandle}
                                        value={bodyFetch.budget_type}
                                        allowAll={false} />
                                </div>
                                <div className="mb-4">
                                    <TextArea
                                        title="Reason"
                                        keyName="reason"
                                        selectorHandle={selectorHandle}
                                        value={bodyFetch.reason} />
                                </div>
                                {
                                    (isAdmin && isRejected) && (
                                        <div className="mb-4">
                                            <TextArea
                                                title="Denial Message"
                                                keyName="denial_msg"
                                                selectorHandle={selectorHandle}
                                                value={bodyFetch.denial_msg} />
                                        </div>
                                    )
                                }

                                <div className="mb-4">
                                    <NumericInput
                                        title="Money"
                                        keyName="amount"
                                        selectorHandle={selectorHandle}
                                        value={bodyFetch.amount} />
                                </div>
                                <div className="mb-4">
                                    <DatePicker
                                        title="Date"
                                        setSelectDate={dateHandle}
                                        selectedDate={bodyFetch.datetime_obj} />
                                </div>
                                {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 shrink-0 stroke-current"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                    <span>{misingsProps}</span>
                                </div>)}
                                {error && (<div role="alert" className="flex rounded-lg w-full alert alert-error">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 shrink-0 stroke-current"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>Write Fail</span>
                                </div>)}
                                {
                                    loading &&
                                    (<progress className="progress w-full progress-info mb-4"></progress>)
                                }
                                <button
                                    onClick={verifyBody}
                                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div> )
            }
        </>

    );
};

