import React, { useState, useRef } from 'react';
import { saveAs } from 'file-saver';
import ComponentFileDb from './ComponentFileDb';
import DeleteCard from './DeleteCard';
import { useReactToPrint } from 'react-to-print';
import { useTypeKey } from '../../hooks/useTypeKey';

export default function DocsViewer(props) {
    const { handleForm, data, handleGetFiles, loading, isAdmin, cardStatus } = props;
    const [showDel, setShowDel] = useState(false);
    const [fileComplete, setFileComplete] = useState(null);
    const containerPrintRef = useRef(null);

    const completeFileHandle = (file) => {
        setFileComplete(file);
    }
    const handleDownload = () => {
        const file2 = new Blob([new Uint8Array(fileComplete.file_binary.data)]);
        saveAs(file2, fileComplete.file_name);
    };

    const handleDelFile = () => {
        handleGetFiles()
        setFileComplete(null)
    }
    const handlePrint = useReactToPrint({
        content: () => containerPrintRef.current,
        documentTitle: "Print This Document",
    })

    const typeKeyHandle=()=>{
        handleForm(false);
    }

    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Docs Viewer</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                        {(!data.length && !loading) && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-info">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 shrink-0 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>There are No Files to show! </span>
                        </div>)}

                        {
                            loading &&
                            (<progress className="progress w-full progress-info mb-4"></progress>)
                        }
                        {
                            fileComplete ?
                                <>
                                    <div className='py-4'>
                                        <button
                                            onClick={handlePrint}
                                            className="btn btn-info mr-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                            </svg>
                                            Print
                                        </button>
                                        <button
                                            onClick={handleDownload}
                                            className="btn btn-success mr-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                            </svg>
                                            Download
                                        </button>
                                        {((cardStatus !== "APPROVED") || isAdmin) && (<button
                                            onClick={() => setShowDel(true)}
                                            className="btn btn-error">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                            </svg>
                                            Delete
                                        </button>)}
                                    </div>
                                    <div onClick={() => setFileComplete(null)} >
                                        <ComponentFileDb
                                            printRef={containerPrintRef}
                                            fileName={fileComplete.file_name}
                                            file={{ data: fileComplete.file_binary.data }}
                                            fistPage={false} />
                                    </div>
                                </>
                                :
                                <div className="grid lg:grid-cols-3 grid-cols-2 gap-4">
                                    {
                                        data.map((file, index) =>
                                            <div key={index}
                                                onClick={() => completeFileHandle(file)}>

                                                <ComponentFileDb
                                                    fileName={file.file_name}
                                                    file={{ data: file.file_binary.data }}
                                                    fistPage={true} />
                                            </div>)

                                    }
                                </div>
                        }
                    </form>
                </div>
            </div>
            {fileComplete && (<DeleteCard
                show={showDel}
                handleForm={setShowDel}
                id={fileComplete.expense_file_id}
                dashboardRequest={handleDelFile}
                name="File" />)}
        </>
    );
}


